import { Col, Row, Table, Pagination, FormInstance, Button, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState, useRef } from "react";
// import { useExportDataMutation, useLazyGetCheckedReportQuery, useLazyGetCheckedReportTotalQuery } from "../redux/checkedReportApi";

interface DataListTable {
    key: number,
    index: number,
    account: string,
    type: string,
    campaign: string,
    totalTHHT: number,
    amountFieldEdited?: number,
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
}

const ReportList = ({ form, searchParam }: Props) => {
    // const [triggerReport, responseReport] = useLazyGetCheckedReportQuery();
    // const [triggerReportTotal, responseReportTotal] = useLazyGetCheckedReportTotalQuery();
    // const [triggerExport] = useExportDataMutation();
    const [tableData, setTableData] = useState<DataListTable[]>()
    const [page, setPage] = useState(1);
    const pageSizeRef = useRef(10);
    const [loading, setLoading] = useState(false);

    
    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        {
            title: "Ngày",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Tên tài khoản",
            dataIndex: "account",
            key: "account",
        },
        {
            title: "Loại",
            dataIndex: "type",
            key: "type",
        },
        {
            title: "Chiến dịch",
            dataIndex: "campaign",
            key: "campaign",
        },
        {
            title: "Số THHT",
            dataIndex: "totalTHHT",
            key: "totalTHHT",
        },
        {
            title: "Số trường chỉnh sửa",
            dataIndex: "amountFieldEdited",
            key: "amountFieldEdited",
        },
    ];

    // useEffect(() => {
    //     getData();
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [searchParam]);

    // useEffect(() => {
    //     if (responseReport?.data?.length) {
    //         let newTableData: DataListTable[] = [];
    //         responseReport?.data.forEach((item, index) => {
    //             newTableData.push({
    //                 key: index + 1,
    //                 index: index + 1,
    //                 account: item.checkedByData,
    //                 book_type: item.typeBook,
    //                 commune: item.communeName,
    //                 date: moment(item.checkedDateData).format("DD-MM-YYYY HH:mm:ss"),
    //                 district: item.districtName,
    //                 edited_count: item.count,
    //                 amount_field_edited: item.amountFieldEdited,
    //                 amount_filed_checked: item.amountFieldChecked,
    //                 province: item.provinceName,
    //             })
    //         })
    //         setTableData(newTableData);
    //     } else {
    //         setTableData([]);
    //     }
    // }, [responseReport]);

    // const getData = ()=>{
    //     triggerReport({
    //         searchParam,
    //         page: 0,
    //         size: pageSizeRef.current,
    //     });
    //     triggerReportTotal({ searchParam });
    //     setPage(1);
    // }
    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        // triggerReport({
        //     searchParam: searchParam ? {
        //         ...searchParam,
        //     } : "",
        //     page: newPage - 1,
        //     size: pageSizeRef.current
        // })
        // triggerReportTotal({ searchParam });
    }

    const onExportExcelClick = () => {
        setLoading(true);
        // triggerExport({ searchParam })
        //     .unwrap()
        //     .then((res) => {
        //         setLoading(false);
        //     })
        //     .catch((err) => {
        //         setLoading(false);
        //         notification.error({ message: err.data?.message || "Có lỗi xảy ra!" })
        //     });
    }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                <Col span={6}></Col>
                <div className='search-box-button'>
                    <Button htmlType="button"
                        type="primary"
                        onClick={onExportExcelClick}
                        loading={loading}
                    >Xuất excel</Button>
                </div>
            </Row>
            <Table<DataListTable>
                // loading={responseReport?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Pagination
                // total={responseReportTotal?.data || 0}
                defaultPageSize={pageSizeRef.current}
                defaultCurrent={1}
                locale={{ items_per_page: ' dòng' }}
                pageSizeOptions={[10, 20, 50, 100]}
                onChange={changePage}
                showSizeChanger
                current={page}
            />
        </div>
    )
}

export default ReportList;