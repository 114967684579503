import { Breadcrumb, Button, Col, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { ReactComponent as Home } from '../../../assets/logos/home.svg';
import { useLazyGetPageDistrictQuery } from "../district/redux/districtApi";
import { useLazyListProvinceQuery } from "../province/redux/provinceApi";
import CommuneList from "./components/CommuneList";
import { CommuneParams } from "./redux/apiTypes";

const Index = () => {
    const [form] = Form.useForm();
    const [searchParam, setSearchParam] = useState<CommuneParams>();
    const [provinceId, setProvinceId] = useState<any>('');

    const [districtTrigger, districtResponse] = useLazyGetPageDistrictQuery();
    const [provinceTrigger, provinceResponse] = useLazyListProvinceQuery();

    useEffect(() => {
        provinceTrigger({});
    }, [provinceTrigger])

    const handleChangeDropdownProvince = (value: { value: string; label: React.ReactNode }) => {
        if (value) {
            setProvinceId(value);
        } else {
            setProvinceId('');
        }
    };

    useEffect(() => {
        districtTrigger({ provinceId: provinceId, size: 2000 });
    }, [districtTrigger, provinceId]);

    const onFinish = (values: any) => {
        setSearchParam(values);
    }

    return (
        <>
            <Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>
                <Breadcrumb.Item href="/home">
                    <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ
                </Breadcrumb.Item>
                <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>
                <Breadcrumb.Item href="">
                    <span>Danh mục Phuờng/Xã</span>
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className='title-label' style={{ marginBottom: 20 }}>Danh mục Phường/Xã</div>

            <div className='content-box' style={{ marginBottom: 30 }}>
                <Form
                    name="searchBox"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    labelWrap
                    labelAlign="left"
                    colon={false}
                // onValuesChange={onValuesChange}
                >
                    <label className='search-title'>Lọc chung</label>
                    <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                        <Col span={8} xs={24} md={12} lg={8} xl={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Mã Phường/Xã"
                                name="communeCode"
                            >
                                <Input allowClear placeholder="Nhập mã phường/xã" style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8} xs={24} md={12} lg={8} xl={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Tên Phường/Xã"
                                name="communeName"
                            >
                                <Input allowClear placeholder="Nhập tên phường/xã" style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8} xs={24} md={12} lg={8} xl={6}>
                            <Form.Item
                                label="Tên Tỉnh/Thành phố"
                                name="provinceId"
                            >
                                <Select
                                    placeholder="--Lựa chọn--"
                                    optionFilterProp="children"
                                    showSearch
                                    listHeight={130}
                                    allowClear
                                    options={provinceResponse?.data?.filter((item) => item.isDelete === 0 && item.status === 0)?.map(item => ({
                                        label: item.provinceName,
                                        value: item.id,
                                    }))}
                                    filterSort={(optionA, optionB) =>
                                        optionA?.label?.toLowerCase().localeCompare(optionB?.label?.toLowerCase())
                                    }
                                    onChange={handleChangeDropdownProvince}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} xs={24} md={12} lg={8} xl={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Tên Quận/Huyện"
                                name="districtId"
                            >
                                <Select
                                    placeholder="--Lựa chọn--"
                                    optionFilterProp="children"
                                    showSearch
                                    listHeight={130}
                                    allowClear
                                    disabled={!provinceId}
                                    options={districtResponse?.data?.content?.filter((item) => item.isDelete === 0 && item.status === 0)?.map(item => ({
                                        label: item.districtName,
                                        value: item.id,
                                    }))}
                                    filterSort={(optionA, optionB) =>
                                        optionA?.label?.toLowerCase().localeCompare(optionB?.label?.toLowerCase())
                                    }
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} xs={24} md={12} lg={8} xl={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Trạng thái"
                                name="status"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn trạng thái"
                                    showArrow
                                >
                                    <Select.Option value={0}>Hoạt động</Select.Option>
                                    <Select.Option value={1}>Không hoạt động</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='search-box-button'>
                        <Button htmlType='button' onClick={() => { form.resetFields(); }}>
                            Xóa điều kiện
                        </Button>
                        <Button htmlType='submit' type='primary'>Tìm kiếm</Button>
                    </div>
                </Form>
            </div>
            <CommuneList form={form} searchParam={searchParam} />
        </>
    )
}

export default Index