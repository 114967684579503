import { Button, Col, Dropdown, FormInstance, Menu, notification, Pagination, Row, Spin, Table, Tag } from 'antd'
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { DataRoleResponse } from '../redux/apiTypes';
import { useDeleteRoleMutation, useLazyGetRoleQuery } from '../redux/permissionsApi';

import { ReactComponent as Manipulation } from "../../../assets/logos/action.svg";
import { ReactComponent as RemoveDetail } from '../../../assets/logos/delete.svg';
import { ReactComponent as AddIcon } from "../../../assets/logos/add_icon.svg";

import ConfirmModalCustom from '../../common/components/ConfirmModalCustom';
import AddRoleModal from './AddRole';
import { DataNode } from 'antd/lib/tree';
import { useAppSelector } from '../../../redux/Hooks';
import { PERMISSION_KEYS } from '../ts/Contants';
import { useNavigate } from 'react-router-dom';

export interface DataListTable {
    key: string;
    status: number;
    roleName: string;
    authoritieLst: DataNode[] | [];
    modifiedBy: string;
    modifiedDate: string;
    description: string;
    // isUsed: number,
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
}

const RoleList = ({ form, searchParam }: Props) => {
    const [tableData, setTableData] = useState<DataListTable[]>([]);
    const [loadingBtnSubmit, setLoadingBtnSubmit] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const pageSizeRef = useRef(10);
    const [currentRole, setCurrentRole] = useState<DataListTable>();
    const [addRole, setAddRole] = useState(false);
    const [deleteRoleVisible, setDeleteRoleVisible] = useState(false);

    const navigate = useNavigate();

    const [roleTrigger, roleResponse] = useLazyGetRoleQuery();
    const [deleteRole] = useDeleteRoleMutation();

    const userState = useAppSelector(state => state.user);
    let userPermissions = userState?.user?.authorities;

    const canDelete = userPermissions?.includes(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_QUYEN.XOA)
    const canAddNew = userPermissions?.includes(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_QUYEN.THEM_MOI)
    const canEdit = userPermissions?.includes(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_QUYEN.SUA)
    const canList = userPermissions?.includes(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_QUYEN.DANH_SACH)


    useEffect(() => {
        if (userState.user?.authorities?.length) {
            if (!canList) {
                navigate('/error/403')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canList, userState])


    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam]);


    useEffect(() => {
        if (roleResponse?.data?.content?.length) {
            let newTableData: DataListTable[] = [];
            // totalPages.current = RoleResponse?.data?.totalPages;
            roleResponse.data.content.forEach((item: DataRoleResponse) => {
                newTableData.push({
                    key: item.id,
                    status: item.status,
                    roleName: item.roleName,
                    authoritieLst: item.authoritieLst,
                    modifiedBy: item.modifiedBy,
                    description: item.description,
                    modifiedDate: item.modifiedDate ? moment(new Date(item.modifiedDate)).format("HH:mm DD/MM/YYYY") : "",
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
            // totalPages.current = 1;
        }
    }, [roleResponse]);

    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, item, index) => (page - 1) * pageSizeRef.current + index + 1,
        },
        {
            title: "Thao Tác",
            key: "action",
            align: "center",
            render: (record) => {
                return (
                    canDelete && <Dropdown placement="bottomLeft" overlay={dropdownOverlay(record)} >
                        <Manipulation onClick={(e) => e.stopPropagation()} />
                    </Dropdown>
                );
            },
        },
        {
            title: "Nhóm quyền",
            dataIndex: "roleName",
            key: "roleName",
            align: "left",
            sorter: {},
        },
        {
            title: "Trạng Thái",
            key: "status",
            dataIndex: "status",
            render: (tag) => (
                <Tag style={{ border: 'none' }} className={`tag-status${!tag ? "_active" : "_err"}`}>
                    {!tag ? "Hoạt động" : "Không hoạt động"}
                </Tag>
            ),
            align: "left",
        },
        {
            title: "Mô tả",
            dataIndex: "description",
            key: "description",
            align: "left",
            sorter: {},
        },
        {
            title: "Ngày cập nhật",
            key: "modifiedDate",
            dataIndex: "modifiedDate",
            align: "left",
        },
        // {
        //     title: "Người cập nhật",
        //     key: "modifiedBy",
        //     dataIndex: "modifiedBy",
        //     align: "left",
        // },
    ];

    const dropdownOverlay = (record: any) => {
        return (
            <div onClick={(e) => e.stopPropagation()}>
                <Menu
                    // items={menuItems}
                    className="menu-actions"
                    style={{ padding: "8px", width: "140px" }}
                >
                    <Menu.Item key="remove-detail" onClick={() => deleteRoleToggle(record)}><RemoveDetail />Xóa</Menu.Item>
                </Menu>
            </div>
        );
    };

    // get table data
    const getData = () => {
        roleTrigger({
            searchParam: searchParam ? {
                ...searchParam
            } : "",
            page: 0,
            size: pageSizeRef.current
        });
        setPage(1);
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        roleTrigger({
            searchParam: searchParam ? {
                ...searchParam
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        });
    }

    // Thêm mới
    const toggleAddRole = (record?: DataListTable) => {
        setAddRole(!addRole);
        if (record) setCurrentRole(record)
    }
    const onAddSuccessful = () => {
        toggleAddRole();
        setCurrentRole(undefined);
        setPage(1);
        roleTrigger({ size: pageSizeRef.current, page: 0, })
    }

    // Xóa 
    const deleteRoleToggle = (record: DataListTable) => {
        setDeleteRoleVisible(!deleteRoleVisible)
        setCurrentRole(record)
    }

    const onDelete = () => {
        if (currentRole) {
            if (!loadingBtnSubmit) {
                setLoadingBtnSubmit(true);
                deleteRole([currentRole.key]).unwrap().then(res => {
                    notification.success({
                        message: 'Xóa Nhóm quyền thành công'
                    })
                    setDeleteRoleVisible(false);
                    setCurrentRole(undefined);
                    setPage(1);
                    form.resetFields();
                    setLoadingBtnSubmit(false);
                    roleTrigger({ size: pageSizeRef.current, page: 0 });
                }).catch(err => {
                    notification.error({
                        message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                    });
                    setLoadingBtnSubmit(false);
                })
            }
        }
    }

    // reset All state
    const resetState = () => {
        setCurrentRole(undefined);
        setDeleteRoleVisible(false);
        setAddRole(false);
    }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                <Col span={6}>
                </Col>
                <div className='search-box-button'>
                    {canAddNew ? <Button
                        htmlType="button"
                        onClick={() => navigate('/setting/role/add')}
                        className="secondary-button"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <AddIcon className='add-icon' style={{ marginRight: 8 }} />
                        Thêm mới
                    </Button> : <></>
                    }
                </div>
            </Row>
            <Table<DataListTable>
                loading={roleResponse?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
                onRow={(record) => {
                    return {
                        onClick: event => navigate(`/setting/role/detail/${record.key}`),
                    }
                }}
            />

            <Spin spinning={roleResponse?.isFetching} tip="Đang tải...">
                <Pagination
                    total={roleResponse?.data?.totalElements || 0}
                    defaultPageSize={pageSizeRef.current}
                    defaultCurrent={1}
                    locale={{ items_per_page: ' dòng' }}
                    pageSizeOptions={[1, 10, 20, 50, 100]}
                    onChange={changePage}
                    showSizeChanger
                    current={page}
                />
            </Spin>


            {/* {addRole && (
                <AddRoleModalv1
                    visible={addRole}
                    onCancel={resetState}
                    currentRole={currentRole}
                    onAddSuccessful={onAddSuccessful}
                    canEdit={canEdit}
                />
            )} */}

            {/* modal xóa Phường/Xã */}
            {deleteRoleVisible && (
                <ConfirmModalCustom
                    onCancel={resetState}
                    cancelText="Đóng"
                    okText="Đồng ý"
                    showCancel={true}
                    showOk={true}
                    type={"error"}
                    danger={true}
                    title="Xóa Nhóm quyền"
                    content="Bạn có chắc chắn muốn xóa Nhóm quyền này hay không?"
                    visible={deleteRoleVisible}
                    onOk={onDelete}
                    loadingBtnOk={loadingBtnSubmit}
                />
            )}

        </div>
    )
}

export default RoleList