import React from 'react';
// import logo from './logo.svg';
import './App.css';
// import Module from './modules/system/participants/components/UserDetails'
import Route from './react-routes/Routes';
import "./assets/css/styles.scss";

function App() {
  return (
    <Route />
  );
}

export default App;
