import { Button, Col, Dropdown, FormInstance, Menu, notification, Pagination, Row, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Action } from '../../../assets/logos/action.svg';
import { ReactComponent as Delete } from '../../../assets/logos/delete.svg';
import { ReactComponent as Lock } from '../../../assets/logos/lock-dots.svg';
import { ReactComponent as Unlock } from '../../../assets/logos/unlock.svg';
import { useDeleteUserMutation, useLazyGetUserQuery } from "../redux/userApi";
import CreateUser from "./CreateUser";
import ConfirmModal from './../../common/components/ConfirmModal';
import { useAppSelector } from "../../../redux/Hooks";
import { PERMISSION_KEYS } from "../../permissions/ts/Contants";
import { endOfDay, startOfDay } from "date-fns";
interface DataListTable {
    key: string,
    index: number,
    createBy?: string,
    createdDate?: string,
    username: string,
    fullName: string,
    email: string,
    phoneNumber: string,
    address: string,
    password: string
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
}


const UserList = ({ form, searchParam }: Props) => {
    const [triggerUser, responseUser] = useLazyGetUserQuery();
    const [tableData, setTableData] = useState<DataListTable[]>()
    const [page, setPage] = useState(1);
    const [currentUser, setCurrentUser] = useState<DataListTable>();
    const pageSizeRef = useRef(10);
    const navigate = useNavigate();
    const [createUserVisible, setCreateUserVisible] = useState(false);
    const [deleteUserVisible, setDeleteUserVisible] = useState(false)
    const [triggerDeleteUser, responseDeleteUser] = useDeleteUserMutation();
    const [idUser, setIdUser] = useState();

    const userState = useAppSelector(state => state.user);
    let userPermissions = userState?.user?.authorities;

    const canDelete = userPermissions?.includes(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.XOA)
    const canAddNew = userPermissions?.includes(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.THEM_MOI)
    const canEdit = userPermissions?.includes(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.SUA)
    const canList = userPermissions?.includes(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.DANH_SACH)


    useEffect(() => {
        if (userState.user?.authorities?.length) {
            if (!canList) {
                navigate('/error/403')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canList, userState])

    useEffect(() => {
        getData();
    }, [searchParam]);

    useEffect(() => {
        if (responseUser?.data?.content?.length) {
            let newTableData: DataListTable[] = [];
            responseUser.data.content.forEach((item, index) => {
                newTableData.push({
                    key: item.id || "",
                    index: index + 1,
                    createBy: item.createBy,
                    createdDate: item.createdDate ? moment(item.createdDate).format("HH:mm DD/MM/YYYY") : "",
                    username: item.username || "",
                    fullName: item.fullName || "",
                    email: item.email || "",
                    phoneNumber: item.phoneNumber || "",
                    address: item.address || "",
                    password: item.password || ""
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
        }
    }, [responseUser?.data?.content]);

    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        {
            title: 'Xử lý',
            key: 'action',
            align: "center",
            render: ((text, record) => (
                <Dropdown overlay={getContent(text, record)}>
                    <Action onClick={(e) => e.stopPropagation()} />
                </Dropdown>
            )
            ),
        },
        {
            title: "Tên đăng nhập",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Họ tên",
            dataIndex: "fullName",
            key: "fullName",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "SĐT",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
        },
        {
            title: "Người tạo",
            dataIndex: "createBy",
            key: "createBy",
        },
        {
            title: "Ngày tạo",
            dataIndex: "createdDate",
            key: "createdDate",
        },
    ];
    const deleteUserToggle = (record: any) => {
        setIdUser(record.key)
        setDeleteUserVisible(!deleteUserVisible)
        // triggerDeleteUser([record.key]).unwrap().then(res => {
        //     notification.success({
        //         message: 'Xóa người dùng thành công.'
        //     })
        // })
        // getData()
        // onSubmitCheckData(record)
    }

    const getContent = (text: string, record: any) => {
        return (
            <div onClick={(e) => e.stopPropagation()}>
                <Menu>
                    {canDelete && <Menu.Item key="delete" onClick={() => deleteUserToggle(record)}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Delete style={{ width: 24, height: 24, marginRight: 10 }} />Xóa
                        </div>
                    </Menu.Item>}

                    <Menu.Item key="activate">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Unlock style={{ width: 24, height: 24, marginRight: 10 }} />
                            Kích hoạt
                        </div>
                    </Menu.Item>
                    <Menu.Item key="lock">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Lock style={{ width: 24, height: 24, marginRight: 10 }} />Khóa
                        </div>
                    </Menu.Item>
                </Menu >
            </div >
        )
    }

    const getData = () => {
        const submitValues = { ...searchParam }
        if (searchParam.startWorkDate) {
            submitValues.startWorkDateFrom = Math.floor(startOfDay(searchParam.startWorkDate[0]).getTime() / 1000);
            submitValues.startWorkDateTo = Math.floor(endOfDay(searchParam.startWorkDate[1]).getTime() / 1000);
            submitValues.startWorkDate = undefined
        }

        triggerUser({
            searchParam: submitValues,
            page: 0,
            size: pageSizeRef.current
        });
        setPage(1);
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        triggerUser({
            searchParam: searchParam ? {
                ...searchParam,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
    }

    const toggleModal = () => {
        if (createUserVisible) {
            setCurrentUser(undefined);
        }
        setCreateUserVisible(!createUserVisible);
    }

    const createUserSuccess = () => {
        toggleModal();
        getData();
    }

    const viewUsers = (e: any, record: DataListTable) => {
        e.stopPropagation();
        navigate(`/data/list`, { replace: true, state: { dataUserId: record.key } });
    }

    const viewDetailUser = (current: DataListTable) => {
        setCurrentUser(current);
        navigate('/setting/user/add', { state: { currentUser: current } })
    }

    const onSubmitCheckData = () => {
        triggerDeleteUser([idUser]).unwrap().then(() => {
            notification.success({
                message: 'Xóa tài khoản người dùng thành công.'
            })
            getData()
        })
        setDeleteUserVisible(false)
    }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                <Col span={6}>
                </Col>
                <div className='search-box-button'>
                    {canAddNew && <Button
                        htmlType="button"
                        type="primary"
                        onClick={() => navigate('/setting/user/add')}
                    >Thêm mới</Button>
                    }
                </div>
            </Row>
            <Table<DataListTable>
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => navigate(`/setting/user/detail/${record.key}`),
                    };
                }}
                loading={responseUser?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />
            {/* <Spin spinning={responseCountDataInfoInit?.isFetching} tip="Đang tải...">
                <Pagination
                    total={responseCountDataInfoInit?.data || 0}
                    defaultPageSize={pageSizeRef.current}
                    defaultCurrent={1}
                    locale={{ items_per_page: ' dòng' }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    onChange={changePage}
                    showSizeChanger
                    current={page}
                />
            </Spin> */}
            <Pagination
                total={responseUser?.data?.totalElements || 0}
                defaultPageSize={pageSizeRef.current}
                defaultCurrent={1}
                locale={{ items_per_page: ' dòng' }}
                pageSizeOptions={[10, 20, 50, 100]}
                onChange={changePage}
                showSizeChanger
                current={page}
            />

            {deleteUserVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText="Xác nhận"
                    classBtnOk="ant-button-dangerous"
                    contents={["Bạn có chắc chắn muốn xóa người dùng này hay không?"]}
                    handleCancel={() => setDeleteUserVisible(false)}
                    handleOk={onSubmitCheckData}
                    type={1}
                    visible={deleteUserVisible}
                    widthConfig={500}
                    okBtnDanger={true}
                /> : <></>}

        </div>



    )
}

export default UserList;