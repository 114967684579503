import { Button, Checkbox, Col, Form, Input, notification, Row, Space, Table } from "antd";
import React, { useEffect, useState } from "react";

import { useAddRoleMutation, useEditRoleMutation, useLazyGetDetailRoleQuery, useLazyGetFullRoleQuery } from "../redux/permissionsApi";
import '../assets/scss/role.scss';

import TextArea from "antd/lib/input/TextArea";
import { Role } from "../redux/apiTypes";
import { ColumnsType } from "antd/lib/table";
import { data } from "../ts/Contants";
import { useNavigate, useParams } from "react-router-dom";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import ConfirmModalCustom from "../../common/components/ConfirmModalCustom";

export interface DataType {
    key: React.Key;
    id: string;
    title: string;
    view: string;
    add: string;
    edit: string;
    delete: string;
    children?: DataType[];
}

const AddRole = () => {
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [addTrigger] = useAddRoleMutation();
    const [editTrigger] = useEditRoleMutation();
    const [getFullRoleTrigger, fullRoleResponse] = useLazyGetFullRoleQuery();
    const [detailRoleTrigger, detailRoleResponse] = useLazyGetDetailRoleQuery();
    const navigate = useNavigate();
    const params = useParams();

    console.log(params);

    // // khi reload
    // const [isFormChange, setFormChange] = useState<boolean>(false);
    // const [reloadModal, setReloadModal] = useState<boolean>(false);
    const [loadingBtnSubmit, setLoadingBtnSubmit] = useState<boolean>(false);
    // usePrompt(isFormChange);

    useEffect(() => {
        getFullRoleTrigger({});
        if (params.id) {
            detailRoleTrigger(params.id);
        }
    }, [detailRoleTrigger, getFullRoleTrigger, params.id])

    useEffect(() => {
        if (detailRoleResponse.data) {
            const data = detailRoleResponse.data;
            let newFormValues = {
                ...form.getFieldsValue(),
                roleName: data.roleName || "",
                description: data.description || "",
            };
            data.authoritieLst.forEach((item: any) => {
                newFormValues[item.id] = true;
            })
            console.log('form', newFormValues);
            form.setFieldsValue(newFormValues)
        }
    }, [detailRoleResponse.data, detailRoleTrigger, form]);


    const onFinish = (values: any) => {
        if (!loadingBtnSubmit) {
            setLoadingBtnSubmit(true);
            let keys = Object.keys(values).filter(k => values[k] === true);
            const authoritieList = fullRoleResponse?.data.filter((role: Role) => keys.includes(role.id));
            if (params?.id) {
                editTrigger({
                    id: params?.id,
                    roleName: values.roleName?.trim() || "",
                    description: values.description?.trim() || "",
                    authoritieLst: authoritieList || [],
                }).unwrap()
                    .then((res: any) => {
                        notification.success({
                            message: 'Cập nhật Nhóm quyền thành công.'
                        });
                        setLoadingBtnSubmit(false);
                        navigate('/setting/role');
                    }
                    ).catch((err: any) => {
                        notification.error({
                            message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                        });
                        setLoadingBtnSubmit(false);
                    })
            }
            else {
                addTrigger({
                    roleName: values.roleName?.trim() || "",
                    description: values.description?.trim() || "",
                    authoritieLst: authoritieList || [],
                }).unwrap()
                    .then((res: any) => {
                        notification.success({
                            message: 'Thêm mới Nhóm quyền thành công.'
                        })
                        setLoadingBtnSubmit(false);
                        navigate('/setting/role');
                    }
                    ).catch((err: any) => {
                        notification.error({
                            message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                        });
                        setLoadingBtnSubmit(false);
                    })
            }
        }
    };

    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
    const _onExpandIconClick = (key: number) => {
        _toggleExpandByKey(key);
    }

    const _toggleExpandByKey = (key: number) => {
        const newExpandedRowKeys = expandedRowKeys.find(x => x === key)
            ? expandedRowKeys.filter(x => x !== key)
            : [...expandedRowKeys, key];
        setExpandedRowKeys(newExpandedRowKeys);
    };

    const handleOnChange = (e: any, record: any, type: string) => {
        const a = record.children.map((child: any) => child[type])

        let newFormValues = { ...form.getFieldsValue() };
        a.forEach((item: any) => {
            newFormValues[item] = e.target.checked
        })

        form.setFieldsValue(newFormValues)
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Phân quyền chức năng',
            dataIndex: 'title',
            render: (text: string) => <a>{text}</a>,
        },
        {
            key: 'action',
            align: "center",
            width: 60,
            render: (record) => {
                const { key } = record;
                const expened = expandedRowKeys.find(x => x === key);
                return Array.isArray(record?.children) && (
                    <span onClick={(e) => e.stopPropagation()}>
                        {expened &&
                            <DownOutlined onClick={() => _onExpandIconClick(key)} className='expand-icon' />}
                        {!expened &&
                            <RightOutlined onClick={() => _onExpandIconClick(key)} className='expand-icon' />}
                    </span>
                );
            },
        },
        {
            title: 'Xem',
            dataIndex: 'view',
            render: (text: any, record: any) => {
                return (
                    <Form.Item name={record?.view} valuePropName="checked">
                        <Checkbox onChange={(e: any) => handleOnChange(e, record, 'view')} />
                    </Form.Item>
                )
            },
            align: 'center'
        },
        {
            title: 'Thêm',
            dataIndex: 'add',
            render: (text: any, record: any) => (
                <Form.Item name={record?.add} valuePropName="checked">
                    <Checkbox onChange={(e: any) => handleOnChange(e, record, 'add')} />
                </Form.Item>
            ),
            align: 'center'
        },
        {
            title: 'Sửa',
            dataIndex: 'edit',
            render: (text: any, record: any) => (
                <Form.Item name={record?.edit} valuePropName="checked">
                    <Checkbox onChange={(e: any) => handleOnChange(e, record, 'edit')} />
                </Form.Item>
            ),
            align: 'center'
        },
        {
            title: 'Xoá',
            dataIndex: 'delete',
            render: (text: any, record: any) => (
                <Form.Item name={record?.delete} valuePropName="checked">
                    <Checkbox onChange={(e: any) => handleOnChange(e, record, 'delete')} />
                </Form.Item>
            ),
            align: 'center'
        },
    ];


    return (
        <div className="create-container">
            <Row align="middle" justify="space-between" style={{ width: '100%' }}>
                <Col>
                    <span className="title">{params?.id ? 'CHỈNH SỬA VAI TRÒ' : 'THÊM MỚI VAI TRÒ'}</span>
                </Col>
                <Col>
                    <Space>
                        <Button danger style={{ borderRadius: 6, padding: '5px 12px' }}>Huỷ</Button>
                        <Button className="submit-btn btn" type="primary" onClick={() => setModalVisible(!modalVisible)}>Lưu</Button>
                    </Space>
                </Col>
            </Row>

            <Form
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                labelAlign="left"
                form={form}
                onFinish={onFinish}
            >
                <Row gutter={20} className='create-section'>
                    <span className='section-title'>Thông tin chung</span>
                    <Col span={24} xs={24} xl={24} xxl={24}>
                        <Form.Item
                            label="Tên nhóm quyền"
                            name="roleName"
                            rules={[
                                { required: true, whitespace: true, message: "Vui lòng nhập tên nhóm quyền" }
                            ]}
                        >
                            <Input maxLength={100} className="propNameInput" placeholder="Nhập tên nhóm quyền" allowClear />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Mô tả"
                            name="description"
                        >
                            <TextArea

                                allowClear
                                maxLength={500}
                                placeholder="Nhập nội dung mô tả"
                                // rows={3} 
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className='create-section'>
                    <span className='section-title'>Thông tin nhóm quyền</span>
                    <Table
                        columns={columns}
                        dataSource={data}
                        expandable={{
                            expandIconColumnIndex: 0,
                            rowExpandable: record => Array.isArray(record.children),
                            showExpandColumn: false,
                            // onExpand: getProductFile,
                            expandedRowKeys: expandedRowKeys,
                        }}
                        pagination={false}
                        style={{ width: '100%', marginTop: 18 }}
                    />
                </Row>

            </Form>


            {modalVisible && (
                <ConfirmModalCustom
                    onCancel={() => setModalVisible(!modalVisible)}
                    cancelText="Huỷ"
                    okText="Đồng ý"
                    showCancel={true}
                    showOk={true}
                    type={"info"}
                    danger={true}
                    title={params?.id ? 'Chỉnh sửa nhóm quyền' : 'Thêm mới nhóm quyền'}
                    content={params?.id ?
                        "Bạn có chắc chắn muốn chỉnh sửa nhóm quyền này hay không?" :
                        "Bạn có chắc chắn muốn thêm mới nhóm quyền này hay không?"
                    }
                    visible={modalVisible}
                    onOk={() => form.submit()}
                    loadingBtnOk={loadingBtnSubmit}
                />
            )}
        </div>
    );
};

export default AddRole;
