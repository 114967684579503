import { Breadcrumb, Button, Col, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { ReactComponent as Home } from '../../assets/logos/home.svg';
import { DatePicker } from "../../custom-components";
import { Commune, District } from "../../redux/api/apiTypes";
import { useLazyGetCommuneQuery, useLazyGetDistrictQuery, useLazyGetProvinceQuery, useLazyGetUserCheckDataQuery } from "../../redux/api/commonApi";
import { ReactComponent as FillterIcon } from '../../assets/logos/fillter.svg';
import BoxListData from "./components/BoxListData";
import FormSearchFilter from './components/FilterDrawer';
const { RangePicker } = DatePicker;

const BoxData = () => {
    const [form] = Form.useForm();
    const [searchParam, setSearchParam] = useState({});
    const [isCheck, setIsCheck] = useState();
    const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckDataQuery();
    const [triggerProvince, responseProvince] = useLazyGetProvinceQuery();
    const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
    const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
    const [districts, setDistricts] = useState<District[]>([]);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [provinceObject, setProvinceObject] = useState();
    const [districtObject, setDistrictObject] = useState();
    const [communeObject, setCommuneObject] = useState();
    const [isFillter, setIsFillter] = useState(false)

    useEffect(() => {
        triggerProvince({});
        triggerUserCheck({});
    }, [triggerUserCheck, triggerProvince])

    const onFinish = (values: any) => {
        setSearchParam(values);
    }

    useEffect(()=>{
        console.log("asshuy",searchParam, typeof searchParam)
    },[searchParam])

    const resetFormData = () => {
        form.resetFields();
    }

    const onValuesChange = (values: any) => {
        if (Object.keys(values)[0] === "provinceId") {
            setCommunes([]);
            setDistricts([]);
            if (values.provinceId) {
                districtTrigger(values.provinceId).unwrap()
                    .then((res: any) => {
                        setDistricts(res);
                        setDistrictObject(res?.reduce((a: any, v: any) => ({ ...a, [v.districtCode]: v.id }), {}));
                    });
            }
            form.setFieldsValue({
                districtCode: undefined,
                communeCode: undefined,
            })

        } else if (Object.keys(values)[0] === "districtCode") {
            setCommunes([]);
            if (values.districtCode) {
                communeTrigger(districtObject?.[values.districtCode]).unwrap()
                    .then((res: any) => {
                        setCommunes(res);
                        setCommuneObject(res?.reduce((a: any, v: any) => ({ ...a, [v.communeCode]: v.id }), {}));
                    });
            }
            form.setFieldsValue({
                communeCode: undefined
            })
        }
        if (Object.keys(values)?.[0] === "isCheck") {
            setIsCheck(values?.isCheck);
            if (values.isCheck === 1) {
                form.setFieldsValue({ numberCheck: 0 });
            } else {
                form.setFieldsValue({ numberCheck: undefined });
            }
        }
        if (Object.keys(values)?.[0] === "numberCheck") {
            setIsCheck(undefined);
            if ((values.numberCheck === 0 && isCheck !== 1) || (values.numberCheck !== 0 && isCheck === 1)) {
                form.setFieldsValue({ isCheck: undefined });
                setIsCheck(undefined);
            }
        }
    }
    const handlerOnFillter = () => {
        setIsFillter(true)
    }
    const resetState = () => {
        setIsFillter(false)
    }

    return (
        <>
            <Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>
                <Breadcrumb.Item href="/home">
                    <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ
                </Breadcrumb.Item>
                <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>
                <Breadcrumb.Item href="">
                    <span>Kho dữ liệu</span>
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className='title-label' style={{ marginBottom: 20 }}>Danh sách dữ liệu</div>

            <div className='content-box' style={{ marginBottom: 30 }}>
                <Form
                    name="searchBox"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    labelWrap
                    labelAlign="left"
                    colon={false}
                    onValuesChange={onValuesChange}
                >
                    <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>

                        <Col span={8} xs={24} md={12} lg={14} xl={15}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                // label="Tên file"
                                name="fileName"
                            >
                                <Input placeholder="Tìm kiếm theo tên file" style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8} xs={24} md={12} lg={4} xl={4}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                // label="Trạng thái"
                                name="isCheck"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn loại dữ liệu"
                                    optionFilterProp='label'
                                    options={([
                                        { id: 1, name: "Chưa nhập" },
                                        { id: 2, name: "Đã nhập" },
                                        { id: 3, name: "Đã nhập xong" },
                                    ]).map(item => ({
                                        label: item.name,
                                        value: item.id
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} xs={24} md={24} lg={6} xl={5} style={{ textAlign: 'center'}}>
                            <FillterIcon onClick={handlerOnFillter} />
                            <Button htmlType='submit' type='primary'>Tìm kiếm</Button>
                        </Col>
                    </Row>
                    <div className='search-box-button'>
                    </div>
                </Form>
            </div>
            <BoxListData form={form} searchParam={searchParam} />

            {isFillter ? <FormSearchFilter
                visible={isFillter}
                onOk={setSearchParam}
                // currentUser={currentUser}
                resetState={resetState}
            /> : <></>}


        </>

    )
}

export default BoxData;