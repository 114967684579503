import { Col, Row, Table, Pagination, FormInstance, Button, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { format } from "date-fns";
import { useEffect, useState, useRef } from "react";
import { exportFileUsingPOST } from "../../../common/assets/CommonFunctions";
import { useExportDataMutation, useLazyGetPerformReportQuery, useLazyGetPerformReportTotalQuery } from "../redux/performReportApi";

interface DataListTable {
    key: number,
    index: number,
    account: string,
    amount_filed_checked?: number,
    book_type?: string,
    commune?: string,
    date?: string,
    district?: string,
    edited_count?: number,
    field_edited_count?: number,
    province?: string,
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
}

const ReportList = ({ form, searchParam }: Props) => {
    const [triggerReport, responseReport] = useLazyGetPerformReportQuery();
    const [triggerReportTotal, responseReportTotal] = useLazyGetPerformReportTotalQuery();
    const [triggerExport] = useExportDataMutation();
    const [tableData, setTableData] = useState<DataListTable[]>()
    const [page, setPage] = useState(1);
    const pageSizeRef = useRef(10);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // console.log(searchParam);
        getData();
    }, [searchParam]);

    useEffect(() => {
        if (responseReport?.data?.length) {
            let newTableData: DataListTable[] = [];
            responseReport.data.forEach((item, index) => {
                newTableData.push({
                    key: index + 1,
                    index: index + 1,
                    account: item.account,
                    amount_filed_checked: item.amount_filed_checked,
                    book_type: item.book_type,
                    commune: item.commune,
                    date: item.date,
                    district: item.district,
                    edited_count: item.edited_count,
                    field_edited_count: item.field_edited_count,
                    province: item.province,
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
        }
    }, [responseReport]);

    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        {
            title: "Thời gian",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Tên tài khoản",
            dataIndex: "account",
            key: "account",
        },
        {
            title: "Tỉnh/Thành",
            dataIndex: "province",
            key: "province",
        },
        {
            title: "Quận/Huyện",
            dataIndex: "district",
            key: "district",
        },
        {
            title: "Phường xã",
            dataIndex: "commune",
            key: "commune",
        },
        {
            title: "Loại dữ liệu",
            dataIndex: "book_type",
            key: "book_type",
        },
        {
            title: "Số THHT đã kiểm tra",
            dataIndex: "amount_filed_checked",
            key: "amount_filed_checked",
        },
        {
            title: "Số THHT có chỉnh sửa",
            dataIndex: "edited_count",
            key: "edited_count",
        },
        {
            title: "Số trường có sửa",
            dataIndex: "field_edited_count",
            key: "field_edited_count",
        },
    ];

    const getData = () => {
        // console.log(searchParam);
        triggerReport({
            searchParam,
            page: 0,
            size: pageSizeRef.current
        });
        triggerReportTotal({ searchParam });
        setPage(1);
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        triggerReport({
            searchParam: searchParam ? {
                ...searchParam,
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
        triggerReportTotal({ searchParam });
    }

    const onExportExcelClick = () => {
        setLoading(true);
        triggerExport({ searchParam })
            .unwrap()
            .then((res) => {
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notification.error({ message: err.data?.message || "Có lỗi xảy ra!" })
            });
    }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                <Col span={6}></Col>
                <div className='search-box-button'>
                    <Button htmlType="button"
                        type="primary"
                        onClick={onExportExcelClick}
                        loading={loading}
                    >Xuất excel</Button>
                </div>
            </Row>
            <Table<DataListTable>
                loading={responseReport?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Pagination
                total={responseReportTotal?.data || 0}
                defaultPageSize={pageSizeRef.current}
                defaultCurrent={1}
                locale={{ items_per_page: ' dòng' }}
                pageSizeOptions={[10, 20, 50, 100]}
                onChange={changePage}
                showSizeChanger
                current={page}
            />
        </div>
    )
}

export default ReportList;