import { Button, Col, Row, Menu, Dropdown, Table, Pagination, FormInstance, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyGetCampaignQuery } from "../redux/campaignApi";
import moment from "moment";
import CreateCampaign from "./CreateDataEntryCampaign";
import { CheckOutlined } from "@ant-design/icons";
interface DataListTable {
    key: string,
    index: number,
    createBy?: string,
    createdDate?: string,
    campaignName: string,
    number: string,
    campaignType: string,
    description: string,
    isDefault?: any,
    type: string,
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
}

const CampaignList = ({ form, searchParam }: Props) => {
    const [triggerCampaign, responseDataInfo] = useLazyGetCampaignQuery();
    const [tableData, setTableData] = useState<DataListTable[]>()
    const [page, setPage] = useState(1);
    const [currentCampaign, setCurrentCampaign] = useState<DataListTable>();
    const pageSizeRef = useRef(10);
    const navigate = useNavigate();
    const [createCampaignVisible, setCreateCampaignVisible] = useState(false);

    useEffect(() => {
        getData();
    }, [searchParam]);

    useEffect(() => {
        if (responseDataInfo?.data?.content?.length) {
            let newTableData: DataListTable[] = [];
            responseDataInfo.data.content.forEach((item, index) => {
                newTableData.push({
                    key: item.id,
                    index: index + 1,
                    createBy: item.createBy,
                    createdDate: item.createdDate ? moment(item.createdDate).format("HH:mm DD/MM/YYYY") : "",
                    campaignName: item.campaignName || "",
                    description: item.description || "",
                    number: item.number && item.number > 0 ? (item.number + "") : (item.percent + "%"),
                    campaignType: item.number && item.number > 0 ? "Phân công tuyệt đối" : "Phân công tương đối",
                    type: item.type ? "Dữ liệu nhập liệu" : "Dữ liệu excel",
                    isDefault: item.isDefault ? <CheckOutlined style={{ color: "#49cc90" }} /> : <></>,
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
        }
    }, [responseDataInfo]);

    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        {
            title: "Mặc định",
            dataIndex: "isDefault",
            key: "isDefault",
            align: "center",
        },
        {
            title: "Nguồn dữ liệu",
            dataIndex: "type",
            key: "type",
        },
        {
            title: "Tên chiến dịch",
            dataIndex: "campaignName",
            key: "campaignName",
            render: (value, record, index) => <span style={{ color: "#1890ff", marginLeft: 10, cursor: "pointer" }} onClick={(e) => viewDetailCampaign(record)}>{record.campaignName}</span>,
        },
        {
            title: "Loại chiến dịch",
            dataIndex: "campaignType",
            key: "campaignType",
        },
        {
            title: "Số lượng/Phần trăm",
            dataIndex: "number",
            key: "number",
        },
        {
            title: "Mô tả",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Người tạo",
            dataIndex: "createBy",
            key: "createBy",
        },
        {
            title: "Ngày tạo",
            dataIndex: "createdDate",
            key: "createdDate",
        },
    ];

    const getData = () => {
        triggerCampaign({
            searchParam,
            page: 0,
            size: pageSizeRef.current
        });
        setPage(1);
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        triggerCampaign({
            searchParam: searchParam ? {
                ...searchParam,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
    }

    const toggleModal = () => {
        if (createCampaignVisible) {
            setCurrentCampaign(undefined);
        }
        setCreateCampaignVisible(!createCampaignVisible);
    }

    const createCampaignSuccess = () => {
        toggleModal();
        getData();
    }

    const viewCampaigns = (e: any, record: DataListTable) => {
        e.stopPropagation();
        navigate(`/data/list`, { replace: true, state: { dataCampaignId: record.key } });
    }

    const viewDetailCampaign = (current: DataListTable) => {
        setCurrentCampaign(current);
        toggleModal();
    }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                <Col span={6}>
                </Col>
                {/* <div className='search-box-button'>
                    <Button
                        htmlType="button"
                        type="primary"
                        onClick={toggleModal}
                    >Tạo chiến dịch</Button>
                </div> */}
            </Row>
            <Table<DataListTable>
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => viewDetailCampaign(record), // click row
                    };
                }}
                loading={responseDataInfo?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Pagination
                total={responseDataInfo?.data?.totalElements || 0}
                defaultPageSize={pageSizeRef.current}
                defaultCurrent={1}
                locale={{ items_per_page: ' dòng' }}
                pageSizeOptions={[10, 20, 50, 100]}
                onChange={changePage}
                showSizeChanger
                current={page}
            />
            {createCampaignVisible ?
                <CreateCampaign
                    currentCampaign={currentCampaign}
                    visible={createCampaignVisible}
                    toggleModal={toggleModal}
                    createCampaignSuccess={createCampaignSuccess}
                /> : <></>}
        </div>
    )
}

export default CampaignList;