import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { logout, setUser } from '../../modules/login/redux/UserSlice'

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      // console.warn('We got a rejected action!')
      // notification.error({ message: "Phiên làm việc của bạn đã hết hạn!" });
      console.log(action);
      if (
        action?.payload?.status === "FETCH_ERROR" ||
        action?.payload?.status === 401 ||
        action?.payload?.data?.status === 401
      ) {
        api.dispatch(logout());
        api.dispatch(setUser({}));
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
      }
    }

    return next(action)
  }