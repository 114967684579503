import { Button, Col, Row, Table, Pagination, FormInstance, notification, Upload, message, Spin } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState, useRef } from "react";
import { useLazyGetCountFilePdfQuery, useLazyGetFilePdfQuery, useUploadFilePdfMutation } from "../redux/filePdfApi";
import moment from "moment";
import { Link } from "react-router-dom";
interface DataListTable {
    key: string,
    index: number,
    fileName?: any,
    createBy?: string,
    contentType?: string,
    createdDate: string,
    isChecked: string,
    numberCheck: number,
    timeComplete?: string,
    status: string,
    provinceCode?: string,
    districtCode?: string,
    communeCode?: string,
    message?: string
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
}

const FilePdfList = ({ form, searchParam }: Props) => {
    const [triggerFilePdf, responseFilePdf] = useLazyGetFilePdfQuery();
    const [triggerFilePdfCount, responseFilePdfCount] = useLazyGetCountFilePdfQuery();
    const [uploadFile, responseDataInfo] = useUploadFilePdfMutation();
    const [tableData, setTableData] = useState<DataListTable[]>()
    const [loadingFile, setLoadingFile] = useState(false);
    const [page, setPage] = useState(1);
    const pageSizeRef = useRef(10);

    useEffect(() => {
        getData();
    }, [searchParam]);

    useEffect(() => {
        if (responseFilePdf?.data?.length) {
            let newTableData: DataListTable[] = [];
            responseFilePdf.data.forEach((item: any, index: number) => {
                newTableData.push({
                    key: item.id,
                    index: index + 1,
                    districtCode: item.districtCode || "",
                    communeCode: item.communeCode || "",
                    fileName: <Link to={`/data/file/detail/${item.id}`}>{item.fileName || ""}</Link>,
                    numberCheck: item.numberCheck || 0,
                    isChecked: item.isChecked ? "Đã nhập xong" : item.numberCheck ? "Chưa nhập xong" : "Chưa nhập",
                    createBy: item.createdBy || "",
                    createdDate: item.createdDate ? moment(new Date(item.createdDate)).format("HH:mm DD/MM/YYYY") : "",
                    timeComplete: item.timeComplete ? moment(item.timeComplete).format("HH:mm DD/MM/YYYY") : "",
                    status: item.status === 1 ? "Có lỗi" : "",
                    message: item.message || "",
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
        }
    }, [responseFilePdf]);
    console.log('responseFilePdfCount', responseFilePdfCount);

    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        {
            title: "Quận huyện",
            dataIndex: "districtCode",
            key: "districtCode",
        },
        {
            title: "Phường xã",
            dataIndex: "communeCode",
            key: "communeCode",
        },
        {
            title: "Tên file",
            dataIndex: "fileName",
            key: "fileName",
        },
        {
            title: "Số THHT",
            dataIndex: "numberCheck",
            key: "numberCheck",
        },
        {
            title: "Trạng thái",
            dataIndex: "isChecked",
            key: "isChecked",
        },
        {
            title: "Có báo lỗi",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Ghi chú",
            dataIndex: "message",
            key: "message",
        },
        {
            title: "Thời gian hoàn thành",
            dataIndex: "timeComplete",
            key: "timeComplete",
        },
        {
            title: "Người tạo",
            dataIndex: "createBy",
            key: "createBy",
        },
        {
            title: "Ngày tạo",
            dataIndex: "createdDate",
            key: "createdDate",
        },
    ];

    const getData = () => {
        triggerFilePdf({
            searchParam,
            page: 0,
            size: pageSizeRef.current
        });
        triggerFilePdfCount({
            searchParam,
        });
        setPage(1);
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        triggerFilePdf({
            searchParam: searchParam ? {
                ...searchParam,
                isCheck: searchParam.isCheck
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        });
        triggerFilePdfCount({
            searchParam: searchParam ? {
                ...searchParam,
                isCheck: searchParam.isCheck
            } : "",
        });
    }

    const beforeUpload = (files: File[]) => {
        let accepted = true;
        let fileList = [...files];
        fileList = fileList.slice(-1);
        Array.from(fileList).forEach(file => {
            if (file.type !== "application/pdf") {
                accepted = false;
                notification.error({ message: `Định dạng file không hợp kệ` });
            }
        })
        return accepted || Upload.LIST_IGNORE;
    }

    const customUpload = (options: any) => {
        setLoadingFile(true);
        options.onProgress({ percent: 0 })
        let formData = new FormData();
        formData.append('file', options.file);
        uploadFile({
            file: formData,
        }).unwrap()
            .then((res: any) => {
                options.onProgress({ percent: 100 });
                options.onSuccess(res, options.file);
                setLoadingFile(false);
                notification.success({ message: "Tải file thành công!" });
                getData();
            }).catch((err: any) => {
                options.onError();
                setLoadingFile(false);
                if (err?.data?.message) {
                    notification.error({
                        message: err?.data?.message
                    })
                }
            })
    }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                <Col span={6}>
                </Col>
                <div className='search-box-button'>
                    <Upload
                        showUploadList={false}
                        customRequest={(options) => customUpload(options)}
                        accept=".pdf"
                        beforeUpload={(file, fileList) => beforeUpload(fileList)}
                        style={{ marginBottom: 0 }}
                        multiple={true}
                        listType="text"
                    >
                        <Button
                            type="primary"
                            loading={loadingFile}
                            style={{ marginBottom: 15, marginLeft: 0 }}
                        >Tải file</Button>
                    </Upload>
                </div>
            </Row>
            <Table<DataListTable>
                loading={responseFilePdf?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Spin spinning={responseFilePdfCount?.isFetching} tip="Đang tải...">
                <Pagination
                    total={responseFilePdfCount?.data || 0}
                    defaultPageSize={pageSizeRef.current}
                    defaultCurrent={1}
                    locale={{ items_per_page: ' dòng' }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    onChange={changePage}
                    showSizeChanger
                    current={page}
                />
            </Spin>
        </div>
    )
}

export default FilePdfList;