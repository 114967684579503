import { Button, Checkbox, Col, Form, Input, notification, Row, Select, Space, Table, Upload } from "antd"
import React, { useEffect, useState } from "react"
import { useAddUserMutation, useEditUserMutation, useLazyGetByIdQuery } from "../redux/userApi"
import { DatePicker } from "../../../custom-components";

import '../assets/scss/user.scss';
import { useLazyGetAllRoleQuery, useLazyGetFullRoleQuery } from "../../permissions/redux/permissionsApi";
import { useNavigate, useParams } from "react-router-dom";
import { data } from "../../permissions/ts/Contants";
import { ColumnsType } from "antd/lib/table";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { DataType } from "../../permissions/components/AddRole";
import ConfirmModalCustom from "../../common/components/ConfirmModalCustom";
import { Role } from "../../permissions/redux/apiTypes";


const CreateUser = () => {
    const [form] = Form.useForm();
    const { Option } = Select;

    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

    const navigate = useNavigate();
    const params = useParams();


    const [addUser] = useAddUserMutation();
    const [editUser] = useEditUserMutation();
    const [roleListTrigger, roleListResponse] = useLazyGetAllRoleQuery();
    const [getFullRoleTrigger, fullRoleResponse] = useLazyGetFullRoleQuery();
    const [detailUserTrigger, detailUserResponse] = useLazyGetByIdQuery();


    // table
    const _onExpandIconClick = (key: number) => {
        _toggleExpandByKey(key);
    }

    const _toggleExpandByKey = (key: number) => {
        const newExpandedRowKeys = expandedRowKeys.find(x => x === key)
            ? expandedRowKeys.filter(x => x !== key)
            : [...expandedRowKeys, key];
        setExpandedRowKeys(newExpandedRowKeys);
    };

    const handleOnChange = (e: any, record: any, type: string) => {
        const a = record.children.map((child: any) => child[type])

        let newFormValues = { ...form.getFieldsValue() };
        a.forEach((item: any) => {
            newFormValues[item] = e.target.checked
        })

        form.setFieldsValue(newFormValues)
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Phân quyền chức năng',
            dataIndex: 'title',
            render: (text: string) => <a>{text}</a>,
        },
        {
            key: 'action',
            align: "center",
            width: 60,
            render: (record) => {
                const { key } = record;
                const expened = expandedRowKeys.find(x => x === key);
                return Array.isArray(record?.children) && (
                    <span onClick={(e) => e.stopPropagation()}>
                        {expened &&
                            <DownOutlined onClick={() => _onExpandIconClick(key)} className='expand-icon' />}
                        {!expened &&
                            <RightOutlined onClick={() => _onExpandIconClick(key)} className='expand-icon' />}
                    </span>
                );
            },
        },
        {
            title: 'Xem',
            dataIndex: 'view',
            render: (text: any, record: any) => {
                return (
                    <Form.Item name={record?.view} valuePropName="checked">
                        <Checkbox onChange={(e: any) => handleOnChange(e, record, 'view')} disabled />
                    </Form.Item>
                )
            },
            align: 'center'
        },
        {
            title: 'Thêm',
            dataIndex: 'add',
            render: (text: any, record: any) => (
                <Form.Item name={record?.add} valuePropName="checked">
                    <Checkbox onChange={(e: any) => handleOnChange(e, record, 'add')} disabled />
                </Form.Item>
            ),
            align: 'center'
        },
        {
            title: 'Sửa',
            dataIndex: 'edit',
            render: (text: any, record: any) => (
                <Form.Item name={record?.edit} valuePropName="checked">
                    <Checkbox onChange={(e: any) => handleOnChange(e, record, 'edit')} disabled />
                </Form.Item>
            ),
            align: 'center'
        },
        {
            title: 'Xoá',
            dataIndex: 'delete',
            render: (text: any, record: any) => (
                <Form.Item name={record?.delete} valuePropName="checked">
                    <Checkbox onChange={(e: any) => handleOnChange(e, record, 'delete')} disabled />
                </Form.Item>
            ),
            align: 'center'
        },
    ];


    // initial data
    useEffect(() => {
        roleListTrigger({});
        getFullRoleTrigger({});
    }, [roleListTrigger])

    useEffect(() => {
        if (params.id) {
            detailUserTrigger(params.id).unwrap().then(res => {
                let newFormValues = {
                    ...form.getFieldsValue(),
                    username: res.username,
                    phoneNumber: res.phoneNumber,
                    fullName: res.fullName,
                    email: res.email,
                    address: res.address,
                    password: res.password,
                    status: res.status,
                    role: res.roles?.[0].id,

                    birthday: res.birthday,
                    gender: res.gender,
                    educationLevel: res.educationLevel,
                    startWorkDate: res.startWorkDate,
                    workType: res.workType,
                    warningCount: res.warningCount,
                    rewardCount: res.rewardCount,
                };
                if (res.roles?.[0]) {
                    res.roles?.[0].authoritieLst.forEach((item: any) => {
                        newFormValues[item.id] = true;
                    })
                }
                form.setFieldsValue(newFormValues)
            }).catch(err => {
                notification.error({ message: "Có lỗi xảy ra!" });
            });
        }
    }, [detailUserTrigger])

    const onFinish = (values: any) => {
        let keys = Object.keys(values).filter(k => values[k] === true);
        const authoritieList = fullRoleResponse?.data.filter((role: Role) => keys.includes(role.id));
        // console.log('submit', {
        //     fullName: values.fullName.trim() || "",
        //     phoneNumber: values.phoneNumber.trim() || "",
        //     email: values.email.trim() || "",
        //     address: values.address.trim() || "",
        //     birthday: new Date(values.birthday).toISOString() || "",
        //     gender: values.gender,
        //     educationLevel: values.educationLevel.trim() || "",
        //     startWorkDate: new Date(values.startWorkDate).toISOString() || "",
        //     workType: values.workType || 0,
        //     warningCount: values.warningCount.trim() || "",
        //     rewardCount: values.rewardCount.trim() || "",

        //     username: values.username.trim() || "",
        //     password: values.password.trim() || "",
        //     status: values.status || 0,

        //     roles: [roleListResponse?.data?.find(role => role.id === values.role)],
        //     authoritieLst: authoritieList || [],
        // })
        if (!params.id) {
            addUser({
                fullName: values.fullName.trim() || "",
                phoneNumber: values.phoneNumber.trim() || "",
                email: values.email.trim() || "",
                address: values.address.trim() || "",
                birthday: new Date(values.birthday).toISOString() || "",
                gender: values.gender,
                educationLevel: values.educationLevel.trim() || "",
                startWorkDate: new Date(values.startWorkDate).toISOString() || "",
                workType: values.workType || 0,
                warningCount: values.warningCount,
                rewardCount: values.rewardCount,

                username: values.username.trim() || "",
                password: values.password.trim() || "",
                status: values.status || 0,

                roles: [roleListResponse?.data?.find(role => role.id === values.role)],
            }).unwrap()
                .then(res => {
                    notification.success({ message: "Tạo tài khoản thành công!" });
                    navigate('/setting/user')
                })
                .catch(err => {
                    notification.error({ message: "Có lỗi xảy ra!" });
                })
        } else {
            // console.log('bbbbb', {
            //     id: params.id,

            //     fullName: values.fullName.trim() || "",
            //     phoneNumber: values.phoneNumber.trim() || "",
            //     email: values.email.trim() || "",
            //     address: values.address.trim() || "",
            //     birthday: new Date(values.birthday).toISOString() || "",
            //     gender: values.gender,
            //     educationLevel: values.educationLevel.trim() || "",
            //     startWorkDate: new Date(values.startWorkDate).toISOString() || "",
            //     workType: values.workType || 0,
            //     warningCount: values.warningCount,
            //     rewardCount: values.rewardCount,

            //     username: values.username.trim() || "",
            //     password: values.password.trim() || "",
            //     status: values.status || 0,

            //     roles: [roleListResponse?.data?.find(role => role.id === values.role)],
            //     authoritieLst: authoritieList || [],
            // });
            editUser({
                id: params.id,

                fullName: values.fullName.trim() || "",
                phoneNumber: values.phoneNumber.trim() || "",
                email: values.email.trim() || "",
                address: values.address.trim() || "",
                birthday: new Date(values.birthday).toISOString() || "",
                gender: values.gender,
                educationLevel: values.educationLevel.trim() || "",
                startWorkDate: new Date(values.startWorkDate).toISOString() || "",
                workType: values.workType || 0,
                warningCount: values.warningCount,
                rewardCount: values.rewardCount,

                username: values.username.trim() || "",
                password: values.password.trim() || "",
                status: values.status || 0,

                roles: [roleListResponse?.data?.find(role => role.id === values.role)],
            }).unwrap()
                .then(res => {
                    notification.success({ message: "Chỉnh sửa tải khoản thành công!" });
                    navigate('/setting/user')
                })
                .catch(err => {
                    notification.error({ message: "Có lỗi xảy ra!" });
                })
        }
    }


    // handle khi change role
    const onValuesChange = (changedValues: any, allValues: any) => {
        if (changedValues.role) {
            let keys = Object.keys(allValues).filter(k => allValues[k] === true);
            const roles = roleListResponse?.data?.find(role => role.id === changedValues.role)?.authoritieLst?.map((child: any) => child['id']);

            if (roles) {
                let newFormValues = { ...form.getFieldsValue() };

                keys.forEach((item: any) => {
                    newFormValues[item] = false;
                })
                roles.forEach((item: any) => {
                    newFormValues[item] = true;
                })
                form.setFieldsValue(newFormValues)
            }
        }
    }

    return (
        <div className='create-container'>
            <Row align="middle" justify="space-between" style={{ width: '100%' }}>
                <Col>
                    <span className="title">{params?.id ? 'CHỈNH SỬA NGƯỜI DÙNG' : 'THÊM MỚI NGƯỜI DÙNG'}</span>
                </Col>
                <Col>
                    <Space>
                        <Button danger style={{ borderRadius: 6, padding: '5px 12px' }} onClick={() => navigate('/setting/user')}>Huỷ</Button>
                        <Button className="submit-btn btn" type="primary" onClick={() => setModalVisible(!modalVisible)}>{params?.id ? 'Lưu' : 'Thêm mới'}</Button>
                    </Space>
                </Col>
            </Row>
            <Form
                name="searchBox"
                autoComplete="off"
                form={form}
                labelWrap
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                labelAlign="left"
                requiredMark={false}
                colon={false}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                scrollToFirstError={{ behavior: 'smooth' }}
            >
                <Row gutter={16} className='create-section'>
                    <span className="section-title">Thông tin cá nhân</span>
                    <Col span={12}>
                        <Form.Item
                            label="Họ tên"
                            name="fullName"
                            rules={[
                                { required: true, whitespace: true, message: "Vui lòng nhập Họ tên." }
                            ]}>
                            <Input placeholder="Nhập họ và tên" allowClear style={{ minWidth: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Số điện thoại"
                            name="phoneNumber"
                            rules={[
                                { required: true, whitespace: true, message: "Vui lòng nhập Số điện thoại." }
                            ]}>
                            <Input placeholder="Nhập số điện thoại" allowClear style={{ minWidth: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                { required: true, whitespace: true, type: 'email', message: "Vui lòng nhập đúng định dạng Email." }
                            ]}>
                            <Input placeholder="Nhập email" allowClear style={{ minWidth: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Địa chỉ"
                            name="address"
                        >
                            <Input.TextArea placeholder="Nhập địa chỉ" allowClear rows={1} maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Ngày sinh"
                            name="birthday"
                        >
                            <DatePicker
                                placeholder="Nhập Ngày sinh"
                                format="DD/MM/Y"
                                style={{ minWidth: '100%' }}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Giới tính"
                            name="gender"
                        >
                            <Select
                                placeholder="Chọn giới tính"
                                allowClear
                            >
                                <Option value={1}>
                                    Nam
                                </Option>
                                <Option value={2}>
                                    Nữ
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Trình độ học vấn"
                            name="educationLevel"
                        >
                            <Input placeholder="Nhập Trình độ học vấn" allowClear style={{ minWidth: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Ngày vào làm"
                            name="startWorkDate"
                        >
                            <DatePicker
                                placeholder="Nhập Ngày vào làm"
                                format="DD/MM/Y"
                                style={{ minWidth: '100%' }}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Hình thức làm việc"
                            name="workType"
                        >
                            <Select
                                placeholder="Chọn hình thức làm việc"
                                allowClear
                            >
                                <Option value={0}>
                                    Online
                                </Option>
                                <Option value={1}>
                                    Offline
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Số lần cảnh báo"
                            name="warningCount"
                        >
                            <Input placeholder="Nhập Số lần cảnh báo" allowClear type='number' style={{ minWidth: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Số lần khen thưởng"
                            name="rewardCount"
                        >
                            <Input placeholder="Nhập Số lần khen thưởng" allowClear type='number' style={{ minWidth: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Hợp đồng"
                            name="contract"
                        >
                            <Upload style={{ minWidth: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className='create-section'>
                    <span className="section-title">Thông tin tài khoản</span>
                    <Col span={8}>
                        <Form.Item
                            label="Tên đăng nhập"
                            name="username"
                            rules={[
                                { required: true, whitespace: true, message: "Vui lòng nhập Tên đăng nhập." }
                            ]}
                        >
                            <Input placeholder="Nhập tên đăng nhập" disabled={params.id ? true : false} allowClear style={{ minWidth: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Mật khẩu"
                            name="password"
                            rules={[
                                { required: true, whitespace: true, message: "Vui lòng nhập mật khẩu." }
                            ]}
                        >
                            <Input.Password placeholder="Nhập mật khẩu" allowClear disabled={params.id ? true : false} style={{ minWidth: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Trạng thái"
                            name="status"
                        >
                            <Select
                                placeholder="Chọn trạng thái"
                                allowClear
                            >
                                <Option value={0}>
                                    Hoạt động
                                </Option>
                                <Option value={1}>
                                    Không hoạt động
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={16} className='create-section'>
                    <span className="section-title">Thông tin phân quyền</span>
                    <Col span={12}>
                        <Form.Item
                            label="Vai trò"
                            name="role"
                            rules={[
                                { required: true, message: "Vui lòng chọn Nhóm quyền" }
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="--Chọn Nhóm quyền--"
                                showSearch
                                optionFilterProp="label"
                                options={roleListResponse?.data?.filter(permission => permission.status === 0 && permission.isDelete === 0).map((item: { id: string; roleName: string; }) => ({
                                    label: item.roleName,
                                    value: item.id,
                                }))}
                                filterSort={(optionA, optionB) =>
                                    optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
                                }
                            >
                            </Select>
                        </Form.Item>
                    </Col>

                    <Table
                        columns={columns}
                        dataSource={data}
                        expandable={{
                            expandIconColumnIndex: 0,
                            rowExpandable: record => Array.isArray(record.children),
                            showExpandColumn: false,
                            expandedRowKeys: expandedRowKeys,
                        }}
                        pagination={false}
                        style={{ width: '100%', marginTop: 18 }}
                    />
                </Row>
            </Form>

            {modalVisible && (
                <ConfirmModalCustom
                    onCancel={() => setModalVisible(!modalVisible)}
                    cancelText="Huỷ"
                    okText="Đồng ý"
                    showCancel={true}
                    showOk={true}
                    type={"info"}
                    danger={true}
                    title={params?.id ? 'Chỉnh sửa người dùng' : 'Thêm mới người dùng'}
                    content={params?.id ?
                        "Bạn có chắc chắn muốn chỉnh sửa người dùng này hay không?" :
                        "Bạn có chắc chắn muốn thêm mới người dùng này hay không?"
                    }
                    visible={modalVisible}
                    onOk={() => form.submit()}
                />
            )}
        </div>
    )
}

export default CreateUser