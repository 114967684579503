import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { CommonAPIResponse, CommonListQuery } from "../../../../redux/api/apiTypes";
import { CommuneParams, DataCommuneResponse } from "./apiTypes";


export const communeApi = createApi({
    reducerPath: 'communeApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1/common/",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: ( builder) => ({
        // getPage danh sách phường xã
        getCommune: builder.query<CommonAPIResponse<DataCommuneResponse>, { searchParam?: CommuneParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `commune/getPage`,
                method: 'GET',
                params: {
                    search: JSON.stringify({
                        communeCode: body?.searchParam?.communeCode,
                        communeName: body?.searchParam?.communeName,
                        districtId: body?.searchParam?.districtId,
                        provinceId: body?.searchParam?.provinceId,
                        status: body?.searchParam?.status,
                    }),
                    page: body.page || 0,
                    size: body.size || 10,
                    sortBy: body.sortBy || "DESC",
                    sortByProperty: body.sortByProperty || "modifiedDate",
                }
            }),
            transformResponse: (response : {data: CommonAPIResponse<DataCommuneResponse>}, meta, arg) => {
                return response.data;
            }
        }),
        // thêm mới phuờng xã
        addCommune: builder.mutation<DataCommuneResponse, Partial<DataCommuneResponse>>({
            query: (body) => ({
                url: `commune`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { body: DataCommuneResponse }, meta, arg
            ) => {
                return response.body;
            },
        }),

        // chỉnh sửa phường xã
        editCommune: builder.mutation<DataCommuneResponse, Partial<DataCommuneResponse>>({
            query: (body) => ({
                url: `commune`,
                method: "PUT",
                body: body,
            }),
            transformResponse: (
                response: { data: DataCommuneResponse, message: string }, meta, arg
            ) => {
                return response.data;
            },
        }),

        // xoá phường xã
        deleteCommune: builder.mutation<DataCommuneResponse[], string[]>({
            query: (body) => ({
                url: `commune/delete`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { data: DataCommuneResponse[], message: string }, meta, arg
            ) => {
                return response.data;
            },
        }),
        
        // khoá
        lockCommune: builder.mutation<DataCommuneResponse[], string[]>({
            query: (body) => ({
                url: `commune/lock`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { data: DataCommuneResponse[], message: string }, meta, arg
            ) => {
                return response.data;
            },
        }),
    
        // mở khoá
        unlockCommune: builder.mutation<DataCommuneResponse[], string[]>({
            query: (body) => ({
                url: `commune/unlock`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { data: DataCommuneResponse[], message: string }, meta, arg
            ) => {
                return response.data;
            },
        }),


    })
})


export const {
    useLazyGetCommuneQuery,
    useAddCommuneMutation,
    useEditCommuneMutation,
    useDeleteCommuneMutation,
    useLockCommuneMutation,
    useUnlockCommuneMutation
} = communeApi;
export default communeApi;