import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CommonAPIResponse } from '../../../../redux/api/apiTypes';
import { DistrictParams, DistrictResponse } from './apiTypes';

export const districtApi = createApi({
    reducerPath: 'districtApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1/common/",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getPageDistrict: builder.query<CommonAPIResponse<DistrictResponse>, Partial<DistrictParams>>({
            query: (body) => {
                let apiParam: Partial<DistrictParams> = {}
                if(body.districtCode){
                    apiParam.districtCode = body.districtCode;
                }
                if(body.districtName){
                    apiParam.districtName = body.districtName;
                }
                if(body.provinceId){
                    apiParam.provinceId = body.provinceId;
                }
                if(typeof body.status ==='number'){
                    apiParam.status = body.status;
                }
                return ({
                    url: `district/getPage`,
                    method: 'GET',
                    params: {
                        search: JSON.stringify(apiParam),
                        page: body.page || 0,
                        size: body.size || 10,
                        sortBy: body.sortBy || "DESC",
                        sortByProperty: body.sortByProperty || "modifiedDate",
                    }
                })
            },
            transformResponse: (response: { data: CommonAPIResponse<DistrictResponse>, message: string }, meta, arg) => {
                return response.data;
            },
        }),
        ListDistrict: builder.query<DistrictResponse[],any>({
            query: (body) => {
                return ({
                    url: `district/all`,
                    method: 'GET',
                })
            },
            transformResponse: (response: { data: DistrictResponse[], message: string }, meta, arg) => {
                return response.data;
            },
        }),

        // Thêm mới Quận/huyện
        addDistrict: builder.mutation<DistrictResponse, Partial<DistrictResponse>>({
            query: (body) => ({
                url: `district`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { body: DistrictResponse }, meta, arg
            ) => {
                return response.body;
            },
        }),
        // Sửa Quận/huyện
        editDistrict: builder.mutation<DistrictResponse, Partial<DistrictResponse>>({
            query: (body) => ({
                url: `district`,
                method: "PUT",
                body: body,
            }),
            transformResponse: (
                response: { data: DistrictResponse, message: string }, meta, arg
            ) => {
                return response.data;
            },
        }),
        
        // Xóa Quận/huyện
        deleteDistrict: builder.mutation<DistrictResponse[], string[]>({
            query: (body) => ({
                url: `district/delete`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { data: DistrictResponse[], message: string }, meta, arg
            ) => {
                return response.data;
            },
        }),
        // Khóa Quận/huyện
        lockDistrict: builder.mutation<DistrictResponse[], string[]>({
            query: (body) => ({
                url: `district/lock`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { data: DistrictResponse[], message: string }, meta, arg
            ) => {
                return response.data;
            },
        }),
        // Kích hoạt Quận/huyện
        unlockDistrict: builder.mutation<DistrictResponse[], string[]>({
            query: (body) => ({
                url: `district/unlock`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { data: DistrictResponse[], message: string }, meta, arg
            ) => {
                return response.data;
            },
        }),

    })
})

export const {
    useLazyGetPageDistrictQuery,
    useLazyListDistrictQuery,
    useAddDistrictMutation,
    useEditDistrictMutation,
    useDeleteDistrictMutation,
    useUnlockDistrictMutation,
    useLockDistrictMutation
} = districtApi;
export default districtApi;