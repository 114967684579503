import { Button, Col, Form, Input, notification, Row, Select, Spin } from "antd";
import { useInsertDataInfoInitMutation, useLazyGetFieldConfigQuery, useLazyGetFilePdfByIdQuery, useLazyGetRandomDataPdfQuery, useUpdateStatusFilePdfMutation } from "../redux/dataInitApi";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { FieldConfig } from "../redux/apiTypes";
import { ReactComponent as Top } from '../../../assets/logos/layout-top-line.svg';
import { ReactComponent as Left } from '../../../assets/logos/layout-left-line.svg';
import { ReactComponent as Right } from '../../../assets/logos/layout-right-line.svg';
import { ReactComponent as ZoomOut } from '../../../assets/logos/zoom-out-line.svg';
import { ReactComponent as ZoomIn } from '../../../assets/logos/zoom-in-line.svg';
import { ReactComponent as Rotate } from '../../../assets/logos/rotate.svg';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { PDFDocumentProxy } from 'react-pdf/node_modules/pdfjs-dist/types/src/display/api';
import ConfirmModal from "../../common/components/ConfirmModal";
import useResizeObserver from '@react-hook/resize-observer';
import { DEFAULT_FIELD_CONFIG, QN_UBND, TYPE_FILE_PDF } from "../../../assets/ts/constant";
import { useLazyGetCommuneAllQuery, useLazyGetCommuneQuery, useLazyGetDistrictAllQuery, useLazyGetDistrictQuery, useLazyGetProvinceQuery } from "../../../redux/api/commonApi";
import { Commune, District } from "../../../redux/api/apiTypes";
import { helpers } from "../../../assets/js/helper";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import ViewFilePdf from "./ViewFilePdf";
import SplitPane from "react-split-pane";
import moment from "moment";

const useWidth = (target: any) => {
    const [width, setWidth] = useState(300);

    useLayoutEffect(() => {
        setWidth(target.current.getBoundingClientRect().width)
    }, [target]);

    useResizeObserver(target, (entry: any) => setWidth(entry.contentRect.width));
    return width;
};

const CreateDataInfo = () => {
    const [form] = Form.useForm();
    const param = useParams();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [numPages, setNumPages] = useState(0);
    const [orderNumber, setOrderNumber] = useState(1);
    const [year, setYear] = useState<string>();
    const [displayFlex, setDisplayFlex] = useState(true);
    const [width, setWidth] = useState<any>();
    const [initWidth, setInitWidth] = useState<any>();
    const [height, setHeight] = useState<any>();
    const [keyMaps, setKeyMaps] = useState<FieldConfig[]>([]);
    const [insertData, { isLoading }] = useInsertDataInfoInitMutation();
    const [updateStatusPdf] = useUpdateStatusFilePdfMutation();
    const [triggerFilePdf, filePdfResponse] = useLazyGetRandomDataPdfQuery();
    const [triggerFilePdfId, detailResponse] = useLazyGetFilePdfByIdQuery();
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [confirmSuccessVisible, setConfirmSuccessVisible] = useState(false);
    const [clearDataVisible, setClearDataVisible] = useState(false);
    const [viewPdfVisible, setViewPdfVisible] = useState(false);
    const [indexCompare, setIndexCompare] = useState<number>();
    const [triggerProvince, responseProvince] = useLazyGetProvinceQuery();
    const [provinceObject, setProvinceObject] = useState();
    const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
    const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
    const [districts, setDistricts] = useState<District[]>([]);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [districtObject, setDistrictObject] = useState();
    const [communeObject, setCommuneObject] = useState();
    const [communeAllTrigger, responseCommuneAll] = useLazyGetCommuneAllQuery();
    const [districtAllTrigger, responseDistrictAll] = useLazyGetDistrictAllQuery();
    const [pdfFileName, setPdfFileName] = useState('');
    const [listActive, setListActive] = useState<String[]>([]);
    const [triggerGetFieldConfig, fieldConfigResponse] = useLazyGetFieldConfigQuery();
    const wrapperDiv = useRef<HTMLInputElement>(null);
    // const width = useWidth(wrapperDiv);
    const file = useMemo(() => {
        return {
            url: process.env.REACT_APP_API_URL + `/api/v1/file/download-pdf/${filePdfResponse?.data?.id}`,
            httpHeaders: {
                Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        }
    }, [filePdfResponse?.data?.id]);

    useEffect(() => {
        triggerFilePdf({});
    }, [triggerFilePdf]);

    useEffect(() => {
        const slider = document.getElementById("block-view-pdf");
        let isDown = false;
        let startX: any;
        let startY: any;
        let scrollLeft: any;
        let scrollTop: any;
        if (slider) {
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                startY = e.pageY - slider.offsetTop;
                scrollLeft = slider.scrollLeft;
                scrollTop = slider.scrollTop;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageY - slider.offsetTop;
                const walk = (x - startY) * 3; //scroll-fast
                slider.scrollTop = scrollTop - walk;
            });
        }
    }, []);

    useEffect(() => {
        if (displayFlex) {
            setWidth((wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 2 || undefined);
            setInitWidth((wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 2 || undefined);
        }
    }, [wrapperDiv?.current?.getBoundingClientRect()?.width, displayFlex]);

    useEffect(() => {
        let typeFile = filePdfResponse?.data?.fileName?.split(".")?.[0];
        if (typeFile && !filePdfResponse.isFetching) {
            triggerGetFieldConfig({ types: typeFile, years: convertYearToTypePdf(typeFile) });
        }
    }, [triggerGetFieldConfig, filePdfResponse?.data?.fileName, filePdfResponse.isFetching]);

    useEffect(() => {
        triggerProvince({}).unwrap().then(res => {
            setProvinceObject(res?.reduce((a: any, v: any) => ({ ...a, [v.provinceCode]: v.id }), {}));
        });
        communeAllTrigger({}).unwrap().then(res => {
            setCommuneObject(res?.reduce((a: any, v: any) => ({ ...a, [v.communeCode]: v.id }), {}));
        });
        districtAllTrigger({}).unwrap().then(res => {
            setDistrictObject(res?.reduce((a: any, v: any) => ({ ...a, [v.districtCode]: v.id }), {}));
        });
    }, [triggerProvince, districtAllTrigger, communeAllTrigger]);

    useEffect(() => {
        if (fieldConfigResponse?.data?.fieldKeyMap?.length &&
            filePdfResponse?.data?.id &&
            provinceObject &&
            districtObject &&
            responseCommuneAll?.data
        ) {
            setInitData(fieldConfigResponse?.data?.fieldKeyMap, undefined);
        } else {
            setKeyMaps([]);
        }
    }, [fieldConfigResponse?.data?.type, fieldConfigResponse?.data?.year, filePdfResponse?.data?.id, provinceObject, districtObject, responseCommuneAll?.data]);


    const convertYearToTypePdf = (typeFile: string) => {
        // KS.1994.01.--.001.PDF
        let yearStr = filePdfResponse?.data?.fileName?.split(".")?.[1];
        let yearNum = yearStr ? parseInt(yearStr) : 0;
        // TODO: LƯU Ý CHECK LẠI RULE NÀY KHI TRIỂN KHAI CHO QUẢNG NGÃI (ĐẶC BIỆT GIAI ĐOẠN <= 1977)
        // Chuyển từ năm sang file loại file: A3, A4
        if(typeFile === "KH" && yearNum === 2007 && filePdfResponse?.data?.communeCode === "xatinhantay") {
            setYear(TYPE_FILE_PDF.A3);
            return TYPE_FILE_PDF.A3;
        }
        if(yearNum === 2007 && filePdfResponse?.data?.communeCode === "xatinhantay") {
            setYear(TYPE_FILE_PDF.A3);
            return TYPE_FILE_PDF.A3;
        }
        if (yearNum > 2006) {
            if (typeFile === "KH" &&
                filePdfResponse?.data?.communeCode === "sotuphap" &&
                filePdfResponse?.data?.districtCode === "thanhphoquangngai") {
                if (yearNum < 2011) {
                    setYear(TYPE_FILE_PDF.A3);
                    return TYPE_FILE_PDF.A3;
                }
            }
            setYear(TYPE_FILE_PDF.A4);
            return TYPE_FILE_PDF.A4;
        }
        if (yearNum > 1977) {
            setYear(TYPE_FILE_PDF.A3);
            return TYPE_FILE_PDF.A3;
        }
        switch (typeFile) {
            case "KS":
                setYear(TYPE_FILE_PDF.A4);
                return TYPE_FILE_PDF.A4
            default:
                setYear(TYPE_FILE_PDF.A4);
                return TYPE_FILE_PDF.A4;
        }
    }

    const onFinish = (values: any) => {
        form.validateFields().then(res => {
            toggleConfirmModal();
        }).catch(err => {
            notification.error({
                message: err?.errorFields?.[0].name?.[0] &&
                    err?.errorFields?.[0].errors?.[0] ?
                    `${err?.errorFields?.[0].name?.[0]}: ${err?.errorFields?.[0].errors?.[0]}!`
                    : "Có lỗi nhập liệu"
            });
        })
    }

    const onSubmitCheckData = (isCheck?: Boolean) => {
        let keyMapsObj = keyMaps.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataValue }), {})
        if (fieldConfigResponse?.data?.type &&
            keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh] &&
            keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen] &&
            keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa]) {
            insertData({
                keyMaps: [keyMaps],
                file_pdf_id: filePdfResponse?.data?.id,
                isCheck: isCheck || false,
                typeId: fieldConfigResponse?.data?.type,
                communeId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa],
                districtId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen],
                provinceId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh],
            }).unwrap()
                .then((res: any) => {
                    let oldData = form.getFieldsValue();
                    notification.success({ message: "Nhập liệu thành công" });
                    resetData();
                    setConfirmVisible(false);
                    if (isCheck) {
                        triggerFilePdf({});
                        triggerFilePdfId(null)
                    } else {
                        // TODO: Không thấy call api chỗ này
                        triggerFilePdfId(filePdfResponse?.data?.id);
                    }
                    setInitData(fieldConfigResponse?.data?.fieldKeyMap, oldData);
                    setListActive([]);
                }).catch((err: any) => {
                    notification.error({ message: err.data?.message || "Cập nhật thất bại" });
                })
        } else {
            notification.error({ message: "Có lỗi nhập liệu!" });
        }
    }

    const onAcceptClearData = () => {
        resetData();
        setInitData(fieldConfigResponse?.data?.fieldKeyMap, undefined);
        setListActive([]);
        toggleClearDataModal();
    }

    const changeLayout = (newDisplayFlex: boolean, order: number) => {
        setDisplayFlex(newDisplayFlex);
        setOrderNumber(order);
        if (!newDisplayFlex) {
            setWidth(initWidth * 2);
        }
    }
    const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
        setNumPages(numPages);
    }

    const toggleConfirmModal = () => {
        setConfirmVisible(!confirmVisible);
    }

    const toggleConfirmSuccessModal = () => {
        setConfirmSuccessVisible(!confirmSuccessVisible);
    }

    const toggleClearDataModal = () => {
        setClearDataVisible(!clearDataVisible);
    }

    const toggleViewPdfModal = () => {
        setViewPdfVisible(!viewPdfVisible);
    }

    const setInitData = (dataKeyMaps: any, oldData?: any) => {
        // TODO: Thêm trường dữ liệu mặc định trong cấu hình trường dữ liệu
        if (dataKeyMaps?.length) {
            setKeyMaps(dataKeyMaps.map((item: any, index: number) => {
                let newItem = setDataItem(item, oldData);
                if (newItem) {
                    return newItem;
                } else if (item.defaultValue) {
                    form.setFieldsValue({
                        [item.dataKeyAscii]: item.defaultValue
                    })
                    return {
                        ...item,
                        dataValue: item.defaultValue
                    }
                }
                return item;
            }))
        }
    }

    // Set dữ liệu ban đầu
    const setDataItem = (item: FieldConfig, oldData?: any) => {
        if (item.dataKeyAscii) {
            switch (item.dataKeyAscii) {
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh:
                    districtTrigger(provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh]).unwrap().then(res => {
                        setDistricts(res);
                    });
                    if (item.dataKeyAscii) {
                        form.setFieldsValue({ [item.dataKeyAscii]: oldData?.[item.dataKeyAscii] || provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh] });
                    }
                    return {
                        ...item,
                        dataValue: oldData?.[item.dataKeyAscii] || provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh] || "",
                    }
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen:
                    if (filePdfResponse?.data?.districtCode) {
                        communeTrigger(districtObject?.[filePdfResponse?.data?.districtCode]).unwrap().then(res => {
                            setCommunes(res);
                        });
                        if (item.dataKeyAscii) {
                            form.setFieldsValue({ [item.dataKeyAscii]: oldData?.[item.dataKeyAscii] || districtObject?.[filePdfResponse?.data?.districtCode] });
                        }
                        return {
                            ...item,
                            dataValue: oldData?.[item.dataKeyAscii] || districtObject?.[filePdfResponse?.data?.districtCode] || "",
                        }
                    }
                    return null;
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa:
                    if (filePdfResponse?.data?.communeCode && filePdfResponse?.data?.districtCode) {
                        let newCommuneObject = responseCommuneAll?.data?.filter((item) => filePdfResponse?.data?.districtCode && item.districtId === districtObject?.[filePdfResponse?.data?.districtCode])?.reduce((a: any, v: any) => ({ ...a, [v.communeCode]: v.id }), {});
                        setCommuneObject(newCommuneObject);
                        if (item.dataKeyAscii) {
                            form.setFieldsValue({ [item.dataKeyAscii]: oldData?.[item.dataKeyAscii] || newCommuneObject?.[filePdfResponse?.data?.communeCode] });
                        }
                        return {
                            ...item,
                            dataValue: oldData?.[item.dataKeyAscii] || newCommuneObject?.[filePdfResponse?.data?.communeCode] || "",
                        }
                    }
                    return null;
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo:
                    let pdfFileNames = filePdfResponse?.data?.fileName?.split('.');
                    let quyenSo = `${pdfFileNames?.[2] || ""}/${pdfFileNames?.[1] || ""}`;
                    if (item.dataKeyAscii) {
                        form.setFieldsValue({ [item.dataKeyAscii]: oldData?.[item.dataKeyAscii] || quyenSo });
                    }
                    return {
                        ...item,
                        dataValue: oldData?.[item.dataKeyAscii] || `${pdfFileNames?.[2] || ""}/${pdfFileNames?.[1] || ""}` || "",
                    }
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf:
                    let pdfFileNames1 = filePdfResponse?.data?.fileName?.split('.');
                    let type = filePdfResponse?.data?.fileName?.split(".")?.[0];
                    let tenFilePdf = `${type}.${pdfFileNames1?.[1] || '--'}.${pdfFileNames1?.[2] || '--'}.--..pdf`;
                    form.setFieldsValue({
                        [DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf]: tenFilePdf,
                    })
                    setPdfFileName(tenFilePdf);
                    return {
                        ...item,
                        dataValue: tenFilePdf,
                    }
                case DEFAULT_FIELD_CONFIG.noiDangKy:
                case DEFAULT_FIELD_CONFIG.noiCap:
                    if (filePdfResponse?.data?.districtCode &&
                        filePdfResponse?.data?.communeCode &&
                        item?.data?.length) {
                        let key = `${DEFAULT_FIELD_CONFIG.tinhThanh}-${filePdfResponse?.data?.districtCode}-${filePdfResponse?.data?.communeCode}`;
                        form.setFieldsValue({
                            [item.dataKeyAscii]: QN_UBND[key],
                        });
                        return {
                            ...item,
                            dataValue: QN_UBND[key],
                        }
                    }
            }
        }
        return null
    }


    const changeData = async (value: any, index: number, item: FieldConfig) => {
        // Nếu không cho sửa thì không được sửa
        if (!item.isEdit) return;
        if (item?.dataKeyAscii) {
            form.setFieldsValue({ [item.dataKeyAscii]: value });
        }
        switch (item.dataKeyAscii) {
            case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh:
                form.setFieldsValue({ quanHuyen: undefined, phuongXa: undefined })
                setDistricts([]);
                setCommunes([]);
                districtTrigger(value).unwrap()
                    .then((res: any) => setDistricts(res));
                break;
            case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen:
                form.setFieldsValue({ phuongXa: undefined })
                setCommunes([]);
                communeTrigger(value).unwrap()
                    .then(res => setCommunes(res));
                break;
        }
        // KS.2007.01.2007-07-11.082.pdf
        let changeFilePdf = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.so ||
            item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo ||
            item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.ngayDangKy;
        let tenFilePdf = "";
        if (changeFilePdf) {
            let type = filePdfResponse?.data?.fileName?.split(".")?.[0];
            let so = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.so ? value : form?.getFieldsValue()?.so;
            let quyenSo = ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo ? value : form?.getFieldsValue()?.quyenSo) || "").split("/");
            let ngayDangKy = ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.ngayDangKy ? value : form?.getFieldsValue()?.ngayDangKy) || "").split(".");
            ngayDangKy = ngayDangKy.length === 3 ? ngayDangKy : [];
            tenFilePdf = `${type}.${quyenSo?.[1] || '--'}.${quyenSo?.[0] || '--'}.${ngayDangKy?.[2] || ""}-${ngayDangKy?.[1] || ""}-${ngayDangKy?.[0] || ""}.${so || ""}.pdf`;
            form.setFieldsValue({
                [DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf]: tenFilePdf,
            })
            setPdfFileName(tenFilePdf);
        }

        let loaiDangKy: any;
        let ngayDangKy1 = ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.ngayDangKy ? value : form?.getFieldsValue()?.ngayDangKy) || "");

        // Loại đăng ký KS
        let changeLoaiDangKy = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.ngayDangKy ||
            item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.nksNgaySinh;
        let nksNgaySinh = ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.nksNgaySinh ? value : form?.getFieldsValue()?.nksNgaySinh) || "");

        // Loại đăng ký KT
        let changeLoaiDangKyKT = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.ngayDangKy ||
            item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.nktNgayChet;
        let nktNgayChet = (item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.nktNgayChet ?
            value : form?.getFieldsValue()?.nktNgayChet) || "";


        if (changeLoaiDangKy && fieldConfigResponse?.data?.type === "KS" && ngayDangKy1 && nksNgaySinh) {
            loaiDangKy = autoFillLoaiDangKy(ngayDangKy1, nksNgaySinh);
        }

        if (changeLoaiDangKyKT && fieldConfigResponse?.data?.type === "KT" && ngayDangKy1 && nktNgayChet) {
            loaiDangKy = autoFillLoaiDangKyKT(ngayDangKy1, nktNgayChet);
        }

        setKeyMaps((keyMaps || []).map((x: any, i: number) => {
            if (x.dataKeyAscii === item.dataKeyAscii) return {
                ...x,
                dataValue: value || "",
            }
            if (x.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf && changeFilePdf) return {
                ...x,
                dataValue: tenFilePdf
            }
            // Tự động điền các tường từ năm thành chữ
            if ((x.dataKeyAscii || "").toLowerCase().includes((item.dataKeyAscii || "").toLowerCase()) &&
                (x.dataKeyAscii || "").toLowerCase().includes("bangchu")) {
                let txt = helpers.dateToWord(value || '');
                form.setFieldsValue({ [x.dataKeyAscii]: txt });
                return {
                    ...x,
                    dataValue: txt
                }
            }
            // Tự động điền trường loại đăng ký cho KS
            if (x.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.loaiDangKy &&
                (changeLoaiDangKy || changeLoaiDangKyKT)) {
                form.setFieldsValue({ [x.dataKeyAscii]: loaiDangKy ? loaiDangKy : undefined });
                return {
                    ...x,
                    dataValue: loaiDangKy ? loaiDangKy : undefined
                }
            }
            return x;
        }));
    }

    // Chuyển trường dữ liệu thành item
    // <ExclamationCircleOutlined title="Đã duyệt qua" style={{ marginLeft: 5, color: "#faad14" }} />
    const convertTypeDataToElement = (item: FieldConfig, index: number) => {
        if (item.data && item.data.length) {
            return <Form.Item
                name={item.dataKeyAscii}
                style={{ marginBottom: 10 }}
                label={<div style={{ display: "flex" }}>
                    {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                        <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                    <div style={{ fontWeight: 700, wordBreak: "break-word" }}>{item.dataKey || ""}</div>
                </div>}
                rules={createRules(item)}
            >
                <Select
                    disabled={!item.isEdit}
                    allowClear
                    placeholder={item.placeholder || "--Lựa chọn--"}
                    optionFilterProp='label'
                    options={(item.data || []).map((x: any) => ({
                        label: `${x.id}${x.name ? (" - " + x.name) : ""}`,
                        value: x.id + ''
                    }))}
                    showSearch
                    showArrow
                    onChange={(value) => changeData(value, index, item)} value={item.dataValue}
                    onBlur={() => onBlurItem(item)}
                >
                </Select>
            </Form.Item>
        }
        if (item.isDefault && fieldConfigResponse?.data?.type && (!item.data || !item.data.length)) {
            switch (item.dataKeyAscii) {
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                                <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                            <div style={{ fontWeight: 700, wordBreak: "break-word" }}>{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            // disabled={!item.isEdit}
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(responseProvince?.data || []).map((item) => ({
                                label: item.provinceName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeData(value, index, item)} value={item.dataValue}
                            onBlur={() => onBlurItem(item)}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                                <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                            <div style={{ fontWeight: 700, wordBreak: "break-word" }}>{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            // disabled={!item.isEdit}
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(districts || []).map((item) => ({
                                label: item.districtName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeData(value, index, item)} value={item.dataValue}
                            onBlur={() => onBlurItem(item)}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                                <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                            <div style={{ fontWeight: 700, wordBreak: "break-word" }}>{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            // disabled={!item.isEdit}
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(communes || []).map((item) => ({
                                label: item.communeName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeData(value, index, item)} value={item.dataValue}
                            onBlur={() => onBlurItem(item)}
                        >
                        </Select>
                    </Form.Item>
            }
        }
        return <Form.Item
            name={item.dataKeyAscii}
            style={{ marginBottom: 10 }}
            label={<div style={{ display: "flex" }}>
                {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                    <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                <div style={{ fontWeight: 700, wordBreak: "break-word" }}>{item.dataKey || ""}</div>
            </div>}
            rules={createRules(item)}
        ><Input.TextArea
                autoFocus={item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.so}
                placeholder={item.placeholder || ""}
                disabled={!item.isEdit || item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo}
                onFocus={(e) => e.currentTarget.select()}
                // onChange={(e) => changeData(e.target.value, index, item)}
                // value={item.dataValue}
                onBlur={(e) => updateData(e.target.value, index, item)}
            /></Form.Item>;
    }

    const onBlurItem = (item: any) => {
        if (listActive.indexOf(item.dataKeyAscii) === -1) {
            setListActive([...listActive, item.dataKeyAscii]);
        }
    }

    // Format lại dữ liệu sau khi nhập liệu
    const updateData = (value: any, index: number, item: FieldConfig) => {
        value = helpers.toTitleCaseFirstChar(value);
        if (item.type === "date") {
            value = helpers.customDate(value);
            changeData(value, index, item);
        } else if (item.type === "time") {
            value = helpers.customTime(value, item.format);
            changeData(value, index, item);
        } else if (item.format?.toLowerCase().includes("capitalcase")) {
            value = helpers.toTitleCase(value);
            changeData(value, index, item);
        }
        changeData((value || "").trim(), index, item);
        form.validateFields([item.dataKeyAscii]);
        onBlurItem(item);
    }


    // Tạo rules validate cho trường dữ liệu
    const createRules = (item: any) => {
        let rules: Object[] = [];
        if (item.isDefault) {
            rules = [...rules, { required: true, message: "Trường dữ liệu bắt buộc" }];
        }
        if (item.format && item.format !== "capitalcase") {
            rules = [...rules, { pattern: new RegExp(item.format), message: "Dữ liệu không đúng định dạng" }];
        }
        return rules;
    }

    const resetData = () => {
        if (fieldConfigResponse?.data?.fieldKeyMap?.length) {
            setKeyMaps(fieldConfigResponse?.data?.fieldKeyMap);
        } else {
            setKeyMaps([]);
        }
        form.resetFields();
    }

    const onSubmitSuccess = () => {
        updateStatusPdf({ id: filePdfResponse?.data?.id, isCheck: true, status: ((!filePdfResponse?.data?.numberCheck && !detailResponse?.currentData?.numberCheck) || year === "A4") }).unwrap().then((res: any) => {
            resetData();
            setInitData(fieldConfigResponse?.data?.fieldKeyMap, undefined);
            triggerFilePdf({});
            triggerFilePdfId(null);
            toggleConfirmSuccessModal();
            setListActive([]);
        })
    }

    const passFile = () => {
        resetData();
        setInitData(fieldConfigResponse?.data?.fieldKeyMap, undefined);
        triggerFilePdf({});
        setListActive([]);
        if (detailResponse?.currentData?.id) {
            triggerFilePdfId(null);
        }
    }

    const checkActiveSave = () => {
        let check = true;
        keyMaps.filter(x => x.isEdit && x.isDisplay).map(item => {
            if (item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh &&
                item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen &&
                item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa &&
                item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo &&
                listActive.indexOf(item.dataKeyAscii) === -1) check = false;
        })
        return check;
    }

    const changeSize = (size?: number) => {
        if (displayFlex) setWidth(size);
        setHeight(size);
    }

    const autoFillLoaiDangKy = (ngayDangKy: string, ngaySinh: string) => {
        let ngayDangKyDate = moment(ngayDangKy, "DD.MM.YYYY").toDate().getTime();
        let ngaySinhDate = moment(ngaySinh, "DD.MM.YYYY").toDate().getTime();
        let timeCompare = (ngayDangKyDate - ngaySinhDate) / (1000 * 60 * 60 * 24);
        if (timeCompare < 0) return undefined;
        if (timeCompare <= 60) return "1";
        if (timeCompare / 365 > 10) return "2";
        if (timeCompare > 60) return "4";
    }


    const autoFillLoaiDangKyKT = (ngayDangKy: string, ngaySinh: string) => {
        let ngayDangKyDate = moment(ngayDangKy, "DD.MM.YYYY").toDate().getTime();
        let ngaySinhDate = moment(ngaySinh, "DD.MM.YYYY").toDate().getTime();
        let timeCompare = (ngayDangKyDate - ngaySinhDate) / (1000 * 60 * 60 * 24);
        if (timeCompare < 0) return undefined;
        if (timeCompare <= 15) return "1";
        if (timeCompare / 365 > 10) return "2";
        if (timeCompare > 15) return "4";
    }

    return (
        // <Spin tip="Đang tải..." spinning={fieldConfigResponse.isFetching}>
        <>
            <div className="content-box" >
                <div className="header-block" style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Top onClick={() => changeLayout(false, 1)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                    <Right onClick={() => changeLayout(true, 3)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                    {/* <Bottom onClick={() => changeLayout(false, 2)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} /> */}
                    <Left onClick={() => changeLayout(true, 1)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                </div>
                <div className="body-block-create-info-init" ref={wrapperDiv}>
                    <SplitPane
                        split={displayFlex ? "vertical" : "horizontal"} size={displayFlex ? width : height}
                        minSize={"50%"}
                        onChange={(size) => changeSize(size)}
                        maxSize={"100%"}
                    >
                        <div
                            style={displayFlex ? {
                                order: orderNumber,
                                padding: 10,
                                width: "100%",
                                // resize: "horizontal",
                                overflow: "auto"
                            } : {
                                // borderBottom: orderNumber === 1 ? "dashed 2px #E5E5E5" : "",
                                order: orderNumber,
                                padding: 10,
                                width: "100%",
                                overflow: "auto"
                            }}
                        >
                            <div className="header-block">
                                <div>
                                    <label className='search-title'>File PDF: {filePdfResponse?.data?.fileName || ''}</label>
                                    <ZoomOut
                                        onClick={() => setScale(scale === 1 ? 1 : scale - 1)}
                                        style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                                    <ZoomIn
                                        onClick={() => setScale(scale + 1)}
                                        style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                                    <Rotate
                                        onClick={() => setRotate(rotate - 90)}
                                        style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                                </div>
                                <div>
                                    <label onClick={() => { toggleViewPdfModal(); setIndexCompare(-1) }} style={{ color: "#1890ff", cursor: "pointer" }} className='search-title'>Xem file trước</label> | <label onClick={() => { toggleViewPdfModal(); setIndexCompare(1) }} style={{ color: "#1890ff", cursor: "pointer" }} className='search-title'>Xem file sau</label>
                                </div>
                            </div>
                            <div
                                style={{
                                    overflowY: "auto",
                                    height: displayFlex ? "calc(100vh - 160px)" : "calc(100% - 55px)",
                                }}
                                id="block-view-pdf"
                            >
                                <Document
                                    file={file}
                                    onLoadSuccess={onDocumentLoadSuccess}>
                                    {Array.from(new Array(numPages), (el, index) => (
                                        <Page
                                            width={width}
                                            height={height}
                                            rotate={rotate}
                                            scale={scale}
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                        />
                                    ))}
                                </Document>
                            </div>
                        </div>
                        <div
                            style={displayFlex ? {
                                order: orderNumber === 1 ? 3 : 1,
                                padding: 10,
                                width: "100%",
                                boxSizing: "border-box",
                                position: "absolute"
                                // resize: "horizontal",
                                // overflow: "auto"
                            } : {
                                // borderBottom: orderNumber !== 1 ? "dashed 2px #E5E5E5" : "",
                                order: orderNumber === 1 ? 3 : 1,
                                padding: 10,
                                width: "100%",
                                boxSizing: "border-box",
                                // position: "absolute"
                            }}
                        >
                            <Form
                                name="searchBox"
                                autoComplete="off"
                                form={form}
                                colon={false}
                                style={{ marginTop: 0 }}
                                layout="vertical"
                                labelWrap
                                labelAlign="left"
                            >
                                <label className='search-title'>Dữ liệu THHT: {pdfFileName}</label>
                                <div style={{ overflowY: "auto", overflowX: "hidden", height: "calc(100vh - 215px)", paddingRight: 10 }}>
                                    <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                                        {keyMaps.length && keyMaps.filter(x => x.isDisplay).map((item: any, index) => {
                                            return (<Col span={8} key={item.dataKeyAscii}>
                                                {convertTypeDataToElement(item, index)}
                                            </Col>)
                                        })}
                                    </Row>
                                </div>
                            </Form>
                            {filePdfResponse?.data?.id ? <div className='search-box-button' style={{ marginTop: 15 }}>
                                {/* <Button onClick={() => passFile()}>Bỏ qua</Button> */}
                                <Button onClick={() => toggleClearDataModal()} htmlType='button'>
                                    Xóa ô nhập liệu
                                </Button>
                                <Button disabled={!checkActiveSave()} type='primary' onClick={onFinish}>Lưu dữ liệu</Button>
                                <Button
                                    type='primary'
                                    style={((!filePdfResponse?.data?.numberCheck && !detailResponse?.currentData?.numberCheck) || year === "A4") ?
                                        { backgroundColor: "#ff4d4f", border: "#ff4d4f" } :
                                        { backgroundColor: "#49cc90", border: "#49cc90" }}
                                    onClick={toggleConfirmSuccessModal}>
                                    {((!filePdfResponse?.data?.numberCheck && !detailResponse?.currentData?.numberCheck) || year === "A4") ?
                                        "Báo lỗi" : `Nhập đủ (${detailResponse?.currentData?.numberCheck || filePdfResponse?.data?.numberCheck})`}
                                </Button>
                            </div> : <></>}
                        </div>
                    </SplitPane>
                </div>
            </div >
            {/* okText={year === "A4" ? undefined : "Lưu (Nhập THHT khác)"} */}
            {confirmVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText={year === "A4" ? undefined : "Lưu (Nhập THHT khác)"}
                    note={year === "A4" ? undefined : "*Chú ý: Lưu (Nhập THHT khác) chỉ áp dụng cho ảnh có nhiều trường hợp hộ tịch"}
                    okSuccess="Lưu (Chuyển file pdf khác)"
                    classBtnOk="ant-button-dangerous"
                    contents={["Bạn có chắc chắn muốn lưu thông tin?"]}
                    handleCancel={toggleConfirmModal}
                    handleOk={() => onSubmitCheckData()}
                    handleSuccess={() => onSubmitCheckData(true)}
                    type={1}
                    visible={confirmVisible}
                    widthConfig={570}
                    okBtnDanger={true} />
                : <></>}
            {confirmSuccessVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText="Xác nhận"
                    classBtnOk="ant-button-dangerous"
                    contents={((!filePdfResponse?.data?.numberCheck && !detailResponse?.currentData?.numberCheck) || year === "A4") ?
                        ["Bạn có chắc chắn muốn báo cáo lỗi!"] :
                        ["Bạn có chắc chắn hoàn thành nhập liệu file này và chuyển sang file khác?",
                            "(Dữ liệu hiện tại đang nhập sẽ không được lưu)"]}
                    handleCancel={toggleConfirmSuccessModal}
                    handleOk={onSubmitSuccess}
                    type={1}
                    visible={confirmSuccessVisible}
                    widthConfig={500}
                    okBtnDanger={true} />
                : <></>}
            {clearDataVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText="Xác nhận"
                    classBtnOk="ant-button-dangerous"
                    contents={["Bạn có chắc chắn muốn xóa ô nhập liệu!"]}
                    handleCancel={toggleClearDataModal}
                    handleOk={onAcceptClearData}
                    type={1}
                    visible={clearDataVisible}
                    widthConfig={500}
                    okBtnDanger={true} />
                : <></>}
            {viewPdfVisible ?
                <ViewFilePdf
                    visible={viewPdfVisible}
                    toggleModal={toggleViewPdfModal}
                    filePdf={filePdfResponse?.data}
                    indexCompare={indexCompare}
                />
                : <></>}
        </>
    )
}

export default CreateDataInfo;