import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CommonListQuery, } from '../../../redux/api/apiTypes';
import { BoxData, BoxDataParams} from './apiTypes';
import { CommonAPIResponse } from './../../../redux/api/apiTypes';

export const boxDataApi = createApi({
    reducerPath: 'boxDataApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getBoxData: builder.query<CommonAPIResponse<BoxData>, { searchParam?: any } & Partial<CommonListQuery>>({
            query: (body) => {
                let apiParam: Partial<BoxDataParams> = {
                    ...body?.searchParam
                }
                console.log("huy123",apiParam)
                // if(body.provinceCode){
                //     apiParam.provinceCode = body.provinceCode;
                // }
                // if(body.provinceName){
                //     apiParam.provinceName = body.provinceName;
                // }
                // if(typeof body.status ==='number'){
                //     apiParam.status = body.status;
                // }
                return ({
                    url: `/tool/document-archive/getPage`,
                    method: 'GET',
                    params: {
                        search: JSON.stringify(apiParam),
                        page: body.page || 0,
                        size: body.size || 100,
                        sortBy: body.sortBy || "DESC",
                        sortByProperty: body.sortByProperty || "modifiedDate",
                    }
                })
            },
            transformResponse: (response: { data: CommonAPIResponse<BoxData>, message: string }, meta, arg) => {
                return response.data;
            },
        }),
    })
})

export const {
    useLazyGetBoxDataQuery,
} = boxDataApi;
export default boxDataApi;