import { Breadcrumb, Button, Checkbox, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { ReactComponent as Home } from '../../assets/logos/home.svg';
import { useEffect, useState } from "react";
import RegisterInfoList from "./components/DataInfoInit";
import { useLocation, useNavigate } from "react-router-dom";
import { Commune, District } from "../../redux/api/apiTypes";
import { useLazyGetAllCampaignQuery, useLazyGetCommuneAllQuery, useLazyGetCommuneQuery, useLazyGetDistrictAllQuery, useLazyGetDistrictQuery, useLazyGetProvinceQuery, useLazyGetUserCheckDataQuery } from "../../redux/api/commonApi";
import { TYPE_DATA } from "../../assets/ts/constant";
import { useAppSelector } from "../../redux/Hooks";
import { subMonths, startOfMonth, endOfMonth, startOfYear, subYears, endOfYear } from "date-fns";
import { DatePicker } from "../../custom-components";
const { RangePicker } = DatePicker;

const RegisterInfo = () => {
    const [form] = Form.useForm();
    let location = useLocation();
    let { state } = useLocation();
    const navigate = useNavigate();
    const [searchParam, setSearchParam] = useState({});
    const userState = useAppSelector(state => state.user.user);
    const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckDataQuery();
    const [triggerCampaign, responseCampaign] = useLazyGetAllCampaignQuery();
    const [triggerProvince, responseProvince] = useLazyGetProvinceQuery();
    const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
    const [districtAllTrigger, responseDistrictAll] = useLazyGetDistrictAllQuery();
    const [communeAllTrigger, responseCommuneAll] = useLazyGetCommuneAllQuery();
    const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
    const [districts, setDistricts] = useState<District[]>([]);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [dataCampaignId, setDataCampaignId] = useState();
    const [isCheck, setIsCheck] = useState<boolean>();
    const [districtObject, setDistrictObject] = useState();
    const [communeObject, setCommuneObject] = useState();

    useEffect(() => {
        if (checkPermission("data/info_init")) {
            triggerCampaign({});
        }
        triggerUserCheck({});
        triggerProvince({});
        communeAllTrigger({}).unwrap().then(res => {
            setCommuneObject(res?.reduce((a: any, v: any) => ({ ...a, [v.communeCode]: v.id }), {}));
        });
        districtAllTrigger({}).unwrap().then(res => {
            setDistrictObject(res?.reduce((a: any, v: any) => ({ ...a, [v.districtCode]: v.id }), {}));
        });
    }, [triggerUserCheck, triggerCampaign, triggerProvince, communeAllTrigger, districtAllTrigger]);

    useEffect(() => {
        console.log("test");
        if (location?.search && communeObject && districtObject && responseProvince) {
            let listSearch = (location?.search || "").split("&") || [];
            let pdfFileName = (listSearch[0] || "").substr(1).replace(".pdf", "").replace(".PDF", "");
            if (!checkPermission("data/info_init")) {
                setSearchParam({
                    fileName: pdfFileName,
                    createBys: [userState.username],
                    provinceIdStr: responseProvince?.data?.[0]?.id,
                    districtIdStr: districtObject?.[listSearch[1]],
                    communeIdStr: communeObject?.[listSearch[2]],
                });
                form.setFieldsValue({
                    fileName: pdfFileName,
                    createBys: [userState.username],
                    provinceIdStr: responseProvince?.data?.[0]?.id,
                    districtIdStr: districtObject?.[listSearch[1]],
                    communeIdStr: communeObject?.[listSearch[2]],
                });
            } else {
                setSearchParam({
                    fileName: pdfFileName,
                    provinceIdStr: responseProvince?.data?.[0]?.id,
                    districtIdStr: districtObject?.[listSearch[1]],
                    communeIdStr: communeObject?.[listSearch[2]]
                });
                form.setFieldsValue({
                    fileName: pdfFileName,
                    provinceIdStr: responseProvince?.data?.[0]?.id,
                    districtIdStr: districtObject?.[listSearch[1]],
                    communeIdStr: communeObject?.[listSearch[2]]
                })
            }
            if (responseProvince?.data?.[0]?.id) {
                districtTrigger(responseProvince?.data?.[0]?.id).unwrap()
                    .then(res => setDistricts(res));
            }
            if (districtObject?.[listSearch[1]]) {
                communeTrigger(districtObject?.[listSearch[1]]).unwrap()
                    .then(res => setCommunes(res));
            }
        } else if (state && typeof state === "object") {
            let newSearchParam: any = { ...state };
            if (newSearchParam?.searchParam?.provinceIdStr) {
                setCommunes([]);
                setDistricts([]);
                districtTrigger(newSearchParam?.searchParam?.provinceIdStr).unwrap()
                    .then(res => setDistricts(res));
            }
            if (newSearchParam?.searchParam?.districtIdStr) {
                setCommunes([]);
                communeTrigger(newSearchParam?.searchParam?.districtIdStr).unwrap()
                    .then(res => setCommunes(res));
            }
            if (newSearchParam?.searchParam?.dataCampaignId) {
                setDataCampaignId(newSearchParam?.searchParam?.dataCampaignId);
                setIsCheck(newSearchParam?.searchParam?.isCheck || undefined);
            }
            if (!checkPermission("data/info_init")) {
                setSearchParam({ ...newSearchParam?.searchParam, createBys: newSearchParam?.searchParam?.createBys ? newSearchParam?.searchParam?.createBys : [userState.username] });
                form.setFieldsValue({ ...newSearchParam?.searchParam, createBys: newSearchParam?.searchParam?.createBys ? newSearchParam?.searchParam?.createBys : [userState.username] });
            } else {
                setSearchParam(newSearchParam?.searchParam);
                form.setFieldsValue(newSearchParam?.searchParam)
            }
        } else if (userState) {
            if (!checkPermission("data/info_init")) {
                setSearchParam(searchParam ? { ...searchParam, createBys: [userState.username] } : { createBys: [userState.username] });
                form.setFieldsValue(searchParam ? { ...searchParam, createBys: [userState.username] } : { createBys: [userState.username] });
            } else {
                setSearchParam(searchParam ? searchParam : {});
                form.setFieldsValue(searchParam ? searchParam : {});
            }
        }
    }, [state, userState, location, responseProvince, districtObject, communeObject]);


    const checkPermission = (path: string) => {
        if (userState.username === "admin" || (userState?.authorities || []).indexOf("ROLE_SYSTEM_USER") !== -1) return true;
        return false;
    }

    const onFinish = (values: any) => {
        setSearchParam({ ...values, createBys: checkPermission("data/info_init") ? values.createBys : [userState.username] });
        navigate(".", { replace: true, state: { searchParam: { ...values, createBys: checkPermission("data/info_init") ? values.createBys : [userState.username] } } });
    }

    const onValuesChanged = (changedValues: any) => {
        if (Object.keys(changedValues)[0] === "provinceIdStr") {
            setCommunes([]);
            setDistricts([]);
            if (changedValues.provinceIdStr) {
                districtTrigger(changedValues.provinceIdStr).unwrap()
                    .then(res => setDistricts(res));
            }
            form.setFieldsValue({
                districtIdStr: undefined,
                communeIdStr: undefined,
            })

        } else if (Object.keys(changedValues)[0] === "districtIdStr") {
            setCommunes([]);
            if (changedValues.districtIdStr) {
                communeTrigger(changedValues.districtIdStr).unwrap()
                    .then(res => setCommunes(res));
            }
            form.setFieldsValue({
                communeIdStr: undefined
            })
        } else if (Object.keys(changedValues)[0] === "dataCampaignId") {
            setDataCampaignId(changedValues.dataCampaignId);
            form.setFieldsValue({
                updateDates: undefined,
                isCheck: undefined
            })
            setIsCheck(undefined);
        } else if (Object.keys(changedValues)[0] === "isCheck") {
            form.setFieldsValue({
                updateDates: undefined
            })
            setIsCheck(changedValues.isCheck === 1);
        }
    }

    const resetFormData = () => {
        form.resetFields();
        setDataCampaignId(undefined);
        setIsCheck(undefined);
    }

    return (
        <>
            <Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>
                <Breadcrumb.Item href="/home">
                    <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ
                </Breadcrumb.Item>
                <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>
                <Breadcrumb.Item href="">
                    <span>Dữ liệu nhập liệu</span>
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className='title-label' style={{ marginBottom: 20 }}>Danh sách dữ liệu nhập liệu</div>

            <div className='content-box' style={{ marginBottom: 30 }}>
                <Form
                    name="searchBox"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    labelWrap
                    labelAlign="left"
                    colon={false}
                    onValuesChange={onValuesChanged}
                >
                    <label className='search-title'>Lọc chung</label>
                    <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Thời gian nhập liệu"
                                name="createDates"
                            >
                                <RangePicker
                                    format="DD/MM/Y"
                                    placeholder={["Bắt đầu", "Kết thúc"]}
                                    ranges={{
                                        'Hôm nay': [new Date(), new Date()],
                                        'Tháng này': [startOfMonth(new Date()), new Date()],
                                        'Tháng trước': [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
                                        '3 tháng gần nhất': [startOfMonth(subMonths(new Date(), 2)), new Date()],
                                        '6 tháng gần nhất': [startOfMonth(subMonths(new Date(), 5)), new Date()],
                                        'Năm nay': [startOfYear(subMonths(new Date(), 5)), new Date()],
                                        'Năm trước': [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))],
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Tỉnh/Thành phố"
                                name="provinceIdStr"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn tỉnh thành"
                                    optionFilterProp='label'
                                    options={(responseProvince?.data || []).map(item => ({
                                        label: item.provinceName,
                                        value: item.id
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Quận/Huyện"
                                name="districtIdStr"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn quận huyện"
                                    optionFilterProp='label'
                                    options={(districts || []).map(item => ({
                                        label: item.districtName,
                                        value: item.id
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Phường/Xã"
                                name="communeIdStr"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn phường xã"
                                    options={(communes || []).map(item => ({
                                        label: item.communeName,
                                        value: item.id
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Loại dữ liệu"
                                name="typeId"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn loại dữ liệu"
                                    optionFilterProp='label'
                                    options={Object.keys(TYPE_DATA).map(item => ({
                                        label: `${TYPE_DATA[item]} (${item})`,
                                        value: item
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Tên file"
                                name="fileName"
                            >
                                <Input placeholder="Nhập tên file" style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6} style={{ display: "flex" }}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Năm"
                                name="fromYear"
                            >
                                <Input placeholder="Từ" style={{ minWidth: '50%' }} />
                            </Form.Item>
                            <Form.Item
                                style={{ margin: "30px 10px 10px", }}
                                name="toYear"
                            >
                                <Input placeholder="Đến" style={{ minWidth: '50%' }} />
                            </Form.Item>
                        </Col>
                        {checkPermission("data/info_init") ? <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Người thực hiện"
                                name="createBys"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn người thực hiện"
                                    options={(responseUserCheck?.data || []).map(item => ({
                                        label: `${item.fullName} (${item.username})`,
                                        value: item.username
                                    }))}
                                    showSearch
                                    mode="multiple"
                                    maxTagCount='responsive'
                                >
                                </Select>
                            </Form.Item>
                        </Col> : <></>}
                        {checkPermission("data/info_init") ? <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Chiến dịch"
                                name="dataCampaignId"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn chiến dịch"
                                    optionFilterProp='label'
                                    options={(responseCampaign?.data || []).map(item => ({
                                        label: item.campaignName,
                                        value: item.id
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col> : <></>}
                        {dataCampaignId ? <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Trạng thái"
                                name="isCheck"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn trạng thái"
                                    optionFilterProp='label'
                                    options={([{ id: 1, name: "Đã kiểm tra" }, { id: 2, name: "Chưa kiểm tra" }]).map(item => ({
                                        label: item.name,
                                        value: item.id
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col> : <></>}
                        {dataCampaignId && isCheck ? <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Thời gian kiểm tra"
                                name="updateDates"
                            >
                                <RangePicker
                                    format="DD/MM/Y"
                                    placeholder={["Bắt đầu", "Kết thúc"]}
                                    ranges={{
                                        'Hôm nay': [new Date(), new Date()],
                                        'Tháng này': [startOfMonth(new Date()), new Date()],
                                        'Tháng trước': [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
                                        '3 tháng gần nhất': [startOfMonth(subMonths(new Date(), 2)), new Date()],
                                        '6 tháng gần nhất': [startOfMonth(subMonths(new Date(), 5)), new Date()],
                                        'Năm nay': [startOfYear(subMonths(new Date(), 5)), new Date()],
                                        'Năm trước': [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))],
                                    }}
                                />
                            </Form.Item>
                        </Col> : <></>}
                        {dataCampaignId && isCheck ? <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Người kiểm tra"
                                name="updateBys"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn người kiểm tra"
                                    options={(responseUserCheck?.data || []).map(item => ({
                                        label: `${item.fullName} (${item.username})`,
                                        value: item.username
                                    }))}
                                    showSearch
                                    mode="multiple"
                                    maxTagCount='responsive'
                                >
                                </Select>
                            </Form.Item>
                        </Col> : <></>}
                    </Row>
                    <div className='search-box-button'>
                        <Button htmlType='button' onClick={() => resetFormData()}>
                            Xóa điều kiện
                        </Button>
                        <Button htmlType='submit' type='primary'>Tìm kiếm</Button>
                    </div>
                </Form>
            </div>
            <RegisterInfoList stateOld={state && typeof state === "object" ? state : {}} form={form} searchParam={searchParam} triggerCampaign={() => triggerCampaign({})} />
        </>
    )
}

export default RegisterInfo;