import { Col, Layout, Menu, Row } from 'antd';
import { ReactComponent as Clipboard } from '../../assets/logos/clipboard-list.svg';
import { ReactComponent as Folder } from '../../assets/logos/folder-open.svg';
import { ReactComponent as Group } from '../../assets/logos/clarity_group-solid.svg';
import { ReactComponent as Home } from '../../assets/logos/home.svg';
import { ReactComponent as FileMenu } from '../../assets/logos/file-menu.svg';

import Icon, { DoubleRightOutlined, MenuFoldOutlined, MenuUnfoldOutlined, SettingOutlined } from '@ant-design/icons';
import './assets/css/index.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../redux/Hooks';
import { useLocation } from "react-router-dom";
import SubMenu from 'antd/lib/menu/SubMenu';
import { PERMISSION_KEYS } from '../../modules/permissions/ts/Contants';

const { Sider } = Layout;

const HomeIcon = () => <Icon component={Home} style={{ fontSize: 18, color: 'black' }} />
const ClipboardIcon = () => <Icon component={Clipboard} style={{ fontSize: 18, color: 'black' }} />
const FolderIcon = () => <Icon component={Folder} style={{ fontSize: 18, color: 'black' }} />
const GroupIcon = () => <Icon component={Group} style={{ fontSize: 18, color: 'black' }} />

type Props = {
    headerHeight: number;
}

const Sidebar = ({ headerHeight }: Props) => {
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const userState = useAppSelector(state => state.user.user);

    const onCollapse = () => {
        setCollapsed(!collapsed);
    }

    let userPermissions = userState?.authorities;


    // quan tri he thong
    const userPermission = userPermissions?.includes(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.DANH_SACH)
    const rolePermission = userPermissions?.includes(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_QUYEN.DANH_SACH)

    // danh muc
    const provincePermission = userPermissions?.includes(PERMISSION_KEYS.DANH_MUC.TINH_THANH.DANH_SACH)
    const districtPermission = userPermissions?.includes(PERMISSION_KEYS.DANH_MUC.QUAN_HUYEN.DANH_SACH)
    const communePermission = userPermissions?.includes(PERMISSION_KEYS.DANH_MUC.PHUONG_XA.DANH_SACH)


    const openKeys = () => {
        let openKey = ['category', 'report-data-info-init'];
        // if (location?.pathname?.includes("report/perform")) openKey = [...openKey, "report"];
        // if (location?.pathname?.includes("report/field")) openKey = [...openKey, "report"];
        // if (location?.pathname?.includes("report/empl")) openKey = [...openKey, "report"];

        // if (location?.pathname?.includes("report-data-info-init/perform")) openKey = [...openKey, "report-data-info-init"];
        // if (location?.pathname?.includes("report-data-info-init/field")) openKey = [...openKey, "report-data-info-init"];
        // if (location?.pathname?.includes("report-data-info-init/empl")) openKey = [...openKey, "report-data-info-init"];
        // if (location?.pathname?.includes("report-data-info-init/create")) openKey = [...openKey, "report-data-info-init"];

        return openKey;
    }

    const selectedKeys = () => {
        let activeKey = 'home';
        if (location?.pathname?.includes("data/list")) activeKey = "data";
        if (location?.pathname?.includes("data/import")) activeKey = "data";
        if (location?.pathname?.includes("data/detail")) activeKey = "data";
        if (location?.pathname?.includes("data/campaign")) activeKey = "data/campaign";
        if (location?.pathname?.includes("data/data-entry-campaign")) activeKey = "data/data-entry-campaign";
        if (location?.pathname?.includes("data/check")) activeKey = "data/check";
        // if (location?.pathname?.includes("report/perform")) activeKey = "perform";
        // if (location?.pathname?.includes("report/field")) activeKey = "field";
        // if (location?.pathname?.includes("report/empl")) activeKey = "empl";

        if (location?.pathname?.includes("report-data-info-init/perform")) activeKey = "data-info-init-perform";
        if (location?.pathname?.includes("report-data-info-init/field")) activeKey = "data-info-init-field";
        if (location?.pathname?.includes("report-data-info-init/empl")) activeKey = "data-info-init-empl";
        if (location?.pathname?.includes("report-data-info-init/import")) activeKey = "data-info-init-import";
        if (location?.pathname?.includes("report-data-info-init/checked")) activeKey = "data-info-init-checked";

        if (location?.pathname?.includes("info_init/create")) activeKey = "info_init/create";
        if (location?.pathname?.includes("info_init/createv2")) activeKey = "info_init/createv2";
        if (location?.pathname?.includes("info_init/createv3")) activeKey = "info_init/createv3";
        if (location?.pathname?.includes("info_init/createLack")) activeKey = "info_init/createLack";
        if (location?.pathname?.includes("data/info_init")) activeKey = "data/info_init";
        if (location?.pathname?.includes("data/field")) activeKey = "data/field";
        if (location?.pathname?.includes("data/file")) activeKey = "data/file";
        if (location?.pathname?.includes("data/check-data")) activeKey = "data/check-data";
        if (location?.pathname?.includes("data/info_init_history")) activeKey = "data/info_init_history";
        if (location?.pathname?.includes("data/user-list")) activeKey = "data/user-list";
        if (location?.pathname?.includes("category/province")) activeKey = "province";
        if (location?.pathname?.includes("category/district")) activeKey = "district";
        if (location?.pathname?.includes("category/commune")) activeKey = "commune";
        if (location?.pathname?.includes("data/box-data")) activeKey = "data/box-data";
        if (location?.pathname?.includes("setting/role")) activeKey = "setting/role";
        return [activeKey];
    }

    const checkPermission = (path: string) => {
        if (userState.username === "admin" || (userState?.authorities || []).indexOf("ROLE_SYSTEM_USER") !== -1) return true;
        return false;
    }

    const checkRoleCheck = () => {
        if (userState.username === "admin" || (userState.username || "").toLowerCase().includes("check")) return true;
        return false;
    }

    return (
        <Sider width={300} className='sidebar' collapsible collapsed={collapsed} trigger={null}>
            <Row align='middle' justify={collapsed ? 'center' : 'space-between'} className='sidebar-header text-center'>
                <Col style={{ display: collapsed ? 'none' : 'block' }}>MENU</Col>
                <Col>
                    {!collapsed ?
                        <MenuFoldOutlined onClick={onCollapse} style={{ fontSize: 18 }} /> :
                        <MenuUnfoldOutlined onClick={onCollapse} style={{ fontSize: 18 }} />}
                </Col>
            </Row>
            <Menu selectedKeys={selectedKeys()} defaultOpenKeys={openKeys()} mode='inline' style={{ height: `calc(100vh - 88px)`, overflowY: 'auto', overflowX: "hidden" }}>
                <Menu.Item key='home' icon={<HomeIcon />}>
                    <Link to="/home">
                        Trang chủ
                    </Link>
                </Menu.Item>
                {checkPermission("data/campaign") && (userState.username === "admin" || userState.username === "trungnt" || userState.username === "thanhTest") ? <Menu.Item key='data/campaign' icon={<GroupIcon />}>
                    <Link to="data/campaign">
                        Chiến dịch
                    </Link>
                </Menu.Item> : <></>}
                {checkPermission("data/data-entry-campaign") && (userState.username === "admin" || userState.username === "huynv" || userState.username === "thanhTest") ? <Menu.Item key='data/data-entry-campaign' icon={<GroupIcon />}>
                    <Link to="data/data-entry-campaign">
                        Chiến dịch nhập liệu
                    </Link>
                </Menu.Item> : <></>}

                {checkPermission("data/field") && (userState.username === "admin" || userState.username === "huynv" || userState.username === "thanhTest") ? <Menu.Item key='data/field' icon={<FolderIcon />}>
                    <Link to="data/field">
                        Cấu hình trường thông tin
                    </Link>
                </Menu.Item> : <></>}
                {checkPermission("data/file") ? <Menu.Item key='data/file' icon={<FolderIcon />}>
                    <Link to="data/file">
                        Danh sách file pdf
                    </Link>
                </Menu.Item> : <></>}
                {/* {!checkRoleCheck() || userState.username === "admin" ? <Menu.Item key='info_init/create' icon={<FolderIcon />}>
                    <Link to="info_init/create">
                        Nhập liệu dữ liệu
                    </Link>
                </Menu.Item> : <></>} */}
                {!checkRoleCheck() || userState.username === "admin" ? <Menu.Item key='info_init/createLack' icon={<FolderIcon />}>
                    <Link to="info_init/createLack">
                        Nhập liệu bổ sung
                    </Link>
                </Menu.Item> : <></>}
                {/* <Menu.Item key='info_init/createv2' icon={<FolderIcon />}>
                    <Link to="info_init/createv2">
                        Nhập liệu có gợi ý
                    </Link>
                </Menu.Item> */}
                {/* <Menu.Item key='info_init/createv2' icon={<FolderIcon />}>
                    <Link to="info_init/createv2">
                        Nhập liệu dữ liệu nhiều
                    </Link>
                </Menu.Item> */}
                {/* <Menu.Item key='info_init/createv3' icon={<FolderIcon />}>
                    <Link to="info_init/createv3">
                        Nhập liệu dữ resize
                    </Link>
                </Menu.Item> */}
                {!checkRoleCheck() || userState.username === "admin" ? <Menu.Item key='data/info_init' icon={<FolderIcon />}>
                    <Link to="data/info_init">
                        Kho dữ liệu nhập liệu
                    </Link>
                </Menu.Item> : <></>}
                {/* {checkPermission("data/list") && userState.username === "admin" ? <Menu.Item key='data' icon={<FolderIcon />}>
                    <Link to="data/list">
                        Kho dữ liệu
                    </Link>
                </Menu.Item> : <></>} */}
                {/* <Menu.Item key='data/check' icon={<FileMenu />}>
                    <Link to="data/check">
                        Kiểm tra dữ liệu
                    </Link>
                </Menu.Item> */}
                {/* Kiểm tra dữ liệu ver2 */}
                {checkRoleCheck() || userState.username === "admin" || userState.username === "huybvtest" ? <Menu.Item key='data/check-data' icon={<FileMenu />}>
                    <Link to="data/check-data">
                        Kiểm tra dữ liệu
                    </Link>
                </Menu.Item> : <></>}
                {/* {checkRoleCheck() && (userState.username === "admin" || userState.username === "huynv" || userState.username === "thanhTest") ?  */}
                {1 ?
                    <Menu.Item key='data/info_init_history' icon={<FolderIcon />}>
                        <Link to="data/info_init_history">
                            Kho dữ liệu kiểm tra
                        </Link>
                    </Menu.Item> : <></>}
                {/* {checkPermission("report") && userState.username === "admin" ? <SubMenu key='report' title='Báo cáo (Dữ liệu excel)' icon={<ClipboardIcon />}>
                    <Menu.Item key='perform'>
                        <Link to='/report/perform' title='Báo cáo hiệu năng chỉnh sửa dữ liệu'>
                            <DoubleRightOutlined /> Báo cáo hiệu năng chỉnh sửa dữ liệu
                        </Link>
                    </Menu.Item>
                    <Menu.Item key='field'>
                        <Link to='/report/field' title='Báo cáo theo trường dữ liệu'>
                            <DoubleRightOutlined /> Báo cáo theo trường dữ liệu
                        </Link>
                    </Menu.Item>
                    <Menu.Item key='empl'>
                        <Link to='/report/empl' title='Báo cáo thời gian làm việc'>
                            <DoubleRightOutlined /> Báo cáo thời gian làm việc
                        </Link>
                    </Menu.Item>
                </SubMenu> : <></>} */}
                {/* {checkPermission("report-data-info-init") && (userState.username === "admin" || userState.username === "huynv" || userState.username === "thanhTest") ?
                    <SubMenu key='report-data-info-init' title='Báo cáo (Dữ liệu nhập liệu)' icon={<ClipboardIcon />}>
                        <Menu.Item key='data-info-init-import'>
                            <Link to='/report-data-info-init/import' title='Báo cáo nhập liệu'>
                                <DoubleRightOutlined /> Báo cáo nhập liệu
                            </Link>
                        </Menu.Item>
                        <Menu.Item key='data-info-init-perform'>
                            <Link to='/report-data-info-init/perform' title='Báo cáo hiệu năng chỉnh sửa dữ liệu'>
                                <DoubleRightOutlined /> Báo cáo hiệu năng chỉnh sửa dữ liệu
                            </Link>
                        </Menu.Item>
                        <Menu.Item key='data-info-init-checked'>
                            <Link to='/report-data-info-init/checked' title='Báo cáo kiểm tra'>
                                <DoubleRightOutlined /> Báo cáo kiểm tra
                            </Link>
                        </Menu.Item>
                        <Menu.Item key='data-info-init-field'>
                            <Link to='/report-data-info-init/field' title='Báo cáo theo trường dữ liệu'>
                                <DoubleRightOutlined /> Báo cáo theo trường dữ liệu
                            </Link>
                        </Menu.Item>
                        <Menu.Item key='data-info-init-empl'>
                            <Link to='/report-data-info-init/empl' title='Báo cáo thời gian làm việc'>
                                <DoubleRightOutlined /> Báo cáo thời gian làm việc
                            </Link>
                        </Menu.Item>
                    </SubMenu> : <></>
                } */}

                {/* {checkPermission("category") && (userState.username === "admin" || userState.username === "huynv" || userState.username === "thanhTest") ? */}
                {/* {(provincePermission || districtPermission || communePermission) ?
                    <SubMenu key='category' title='Quản lý danh mục' icon={<ClipboardIcon />}>
                        {provincePermission && <Menu.Item key='province'>
                            <Link to='/category/province' title='Danh mục Tỉnh thành'>
                                <DoubleRightOutlined /> Danh mục Tỉnh thành
                            </Link>
                        </Menu.Item>}
                        {districtPermission && <Menu.Item key='district'>
                            <Link to='/category/district' title='Danh mục Quận huyện'>
                                <DoubleRightOutlined /> Danh mục Quận huyện
                            </Link>
                        </Menu.Item>}
                        {communePermission && <Menu.Item key='commune'>
                            <Link to='/category/commune' title='Danh mục Phường xã'>
                                <DoubleRightOutlined /> Danh mục Phường xã
                            </Link>
                        </Menu.Item>}
                    </SubMenu>
                    : <></>} */}

                {/* {checkPermission("setting") && userState.username === "admin" ?
=======
                    </SubMenu> : <></>}

                {/* Kho dữ liệu */}

                {/* <Menu.Item key='/data/box-data' icon={<FolderIcon />}>
                    <Link to='/data/box-data' title='Danh sách dữ liệu'>
                        Kho dữ liệu
                    </Link>
                </Menu.Item> */}

                {/* {checkPermission("setting") && (userState.username === "admin" || userState.username === "hiepdeptrai" || userState.username === "huynv" || userState.username === "thanhTest") ? */}
                {/* {(userPermission || rolePermission) ?
                    <SubMenu key='setting' title='Quản trị hệ thống' icon={<SettingOutlined />}>
                        {userPermission && <Menu.Item key='/setting/user'>
                            <Link to='/setting/user' title='Quản lý người dùng'>
                                <DoubleRightOutlined /> Quản lý người dùng
                            </Link>
                        </Menu.Item>}
                        {rolePermission && <Menu.Item key='/setting/role'>
                            <Link to='/setting/role' title='Nhóm quyền'>
                                <DoubleRightOutlined /> Phân quyền
                            </Link>
                        </Menu.Item>}
                    </SubMenu>
                    : <></>
                } */}
            </Menu>
        </Sider >
    )
}

export default Sidebar;