import { Breadcrumb, Button, Col, Form, Row, Select } from "antd";
import { ReactComponent as Home } from '../../../assets/logos/home.svg';
import { useEffect, useState } from "react";
import ReportList from "./components/ReportList";
import { useLazyGetUserCheckDataQuery } from "../../../redux/api/commonApi";
import { TYPE_DATA } from "../../../assets/ts/constant";
import { DatePicker } from "../../../custom-components";
import { subMonths, startOfMonth, endOfMonth, startOfYear, subYears, endOfYear } from "date-fns";
import { useLocation } from "react-router-dom";
const { RangePicker } = DatePicker;

const CheckedReport = () => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [searchParam, setSearchParam] = useState({});
    const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckDataQuery();

    useEffect(() => {
        triggerUserCheck({});
        if (state && typeof state === "object") {
            let newSearchParam: any = { ...state };
            setSearchParam(newSearchParam);
            form.setFieldsValue(newSearchParam)
        } else {
            form.setFieldsValue({ dates: [new Date(), new Date()] });
            setSearchParam({ dates: [new Date(), new Date()] });
        }
    }, [triggerUserCheck, state, form]);

    // const onValuesChanged = (changedValues: any) => {
    //     if (Object.keys(changedValues)[0] === "provinceIds") {
    //         setCommunes([]);
    //         setDistricts([]);
    //         if (changedValues.provinceIds && changedValues.provinceIds.length === 1) {
    //             districtTrigger(changedValues.provinceIds[0]).unwrap()
    //                 .then((res: any) => setDistricts(res));
    //         } else {
    //             setDistricts([]);
    //         }
    //         form.setFieldsValue({
    //             districtId: undefined,
    //             communeId: undefined,
    //         })

    //     } else if (Object.keys(changedValues)[0] === "districtIds") {
    //         setCommunes([]);
    //         if (changedValues.districtIds && changedValues.districtIds.length === 1) {
    //             communeTrigger(changedValues.districtIds[0]).unwrap()
    //                 .then((res: any) => setCommunes(res));
    //         } else {
    //             setCommunes([]);
    //         }
    //         form.setFieldsValue({
    //             communeId: undefined
    //         })
    //     }
    // }

    const onFinish = (values: any) => {
        setSearchParam(values);
    }

    const resetFormData = () => {
        form.resetFields();
    }

    return (
        <>
            <Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>
                <Breadcrumb.Item href="/home">
                    <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ
                </Breadcrumb.Item>
                <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>
                <Breadcrumb.Item href="">
                    <span>Báo cáo kiểm tra</span>
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className='title-label' style={{ marginBottom: 20 }}>Báo cáo kiểm tra</div>

            <div className='content-box' style={{ marginBottom: 30 }}>
                <Form
                    name="searchBox"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    labelWrap
                    labelAlign="left"
                    colon={false}
                >
                    <label className='search-title'>Lọc chung</label>
                    <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                        <Col span={8} xs={24} md={12} lg={12} xl={8}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Thời gian"
                                name="dates"
                            >
                                <RangePicker
                                    format="DD/MM/Y"
                                    placeholder={["Bắt đầu", "Kết thúc"]}
                                    ranges={{
                                        'Hôm nay': [new Date(), new Date()],
                                        'Tháng này': [startOfMonth(new Date()), new Date()],
                                        'Tháng trước': [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
                                        '3 tháng gần nhất': [startOfMonth(subMonths(new Date(), 2)), new Date()],
                                        '6 tháng gần nhất': [startOfMonth(subMonths(new Date(), 5)), new Date()],
                                        'Năm nay': [startOfYear(subMonths(new Date(), 5)), new Date()],
                                        'Năm trước': [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))],
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8} xs={24} md={12} lg={12} xl={8}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Loại dữ liệu"
                                name="typeBooks"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn loại dữ liệu"
                                    optionFilterProp='label'
                                    options={Object.keys(TYPE_DATA).map(item => ({
                                        label: `${TYPE_DATA[item]} (${item})`,
                                        value: item
                                    }))}
                                    showSearch
                                    showArrow
                                    maxTagCount={"responsive"}
                                    mode="multiple"
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} xs={24} md={12} lg={12} xl={8}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Người thực hiện"
                                name="checkedBys"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn người thực hiện"
                                    options={(responseUserCheck?.data || []).map((item: any) => ({
                                        label: `${item.fullName || ''} (${item.username})`,
                                        value: item.username
                                    }))}
                                    showSearch
                                    mode="multiple"
                                    maxTagCount='responsive'
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} xs={24} md={12} lg={12} xl={8}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Chiến dịch"
                                name="campaignIds"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn chiến dịch"
                                    optionFilterProp='label'
                                    // options={(responseProvince?.data || []).map((item: any) => ({
                                    //     label: item.provinceName,
                                    //     value: item.id
                                    // }))}
                                    showSearch
                                    showArrow
                                    mode="multiple"
                                    maxTagCount='responsive'
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='search-box-button'>
                        <Button htmlType='button' onClick={() => resetFormData()}>
                            Xóa điều kiện
                        </Button>
                        <Button htmlType='submit' type='primary'>Tìm kiếm</Button>
                    </div>
                </Form>
            </div>
            <ReportList form={form} searchParam={searchParam} />
        </>
    )
}

export default CheckedReport;