import { Breadcrumb, Button, Col, Form, Input, Row, Select } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import RoleList from './components/RoleList';
import { useLazyGetFullRoleQuery } from './redux/permissionsApi'

import { ReactComponent as Home } from '../../assets/logos/home.svg';

const Index = () => {
    const [form] = Form.useForm();
    const [searchParam, setSearchParam] = useState('');
    const [permissionsTrigger, permissionsResponse] = useLazyGetFullRoleQuery();

    useEffect(() => {
        permissionsTrigger({});
    }, [permissionsTrigger])

    // console.log('bbb', permissionsResponse);

    // console.log('aaaa',
    //     _.chain(permissionsResponse.data)
    //         // Group the elements of Array based on `color` property
    //         .groupBy("parentId")
    //         // `key` is group's name (color), `value` is the array of objects
    //         .map((value: any, key: any) => {
    //             return { parent: value.authoritieName, children: value }
    //         })
    //         .value()
    // );


    const onFinish = (values: any) => {
        setSearchParam(values);
    }

    return (
        <>
            <Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>
                <Breadcrumb.Item href="/home">
                    <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ
                </Breadcrumb.Item>
                <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>
                <Breadcrumb.Item href="">
                    <span>Nhóm quyền</span>
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className='title-label' style={{ marginBottom: 20 }}>Danh sách Nhóm quyền</div>

            <div className='content-box' style={{ marginBottom: 30 }}>
                <Form
                    name="searchBox"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    labelWrap
                    labelAlign="left"
                    colon={false}
                // onValuesChange={onValuesChange}
                >
                    <label className='search-title'>Lọc chung</label>
                    <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                        <Col span={8} xs={24} md={12} lg={8} xl={8}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Tên nhóm quyền"
                                name="roleName"
                            >
                                <Input allowClear placeholder="Nhập tên nhóm quyền" style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8} xs={24} md={12} lg={8} xl={8}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Mô tả"
                                name="description"
                            >
                                <Input allowClear placeholder="Nhập mô tả" style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8} xs={24} md={12} lg={8} xl={8}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Trạng thái"
                                name="status"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn trạng thái"
                                    showArrow
                                >
                                    <Select.Option value={0}>Hoạt động</Select.Option>
                                    <Select.Option value={1}>Không hoạt động</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='search-box-button'>
                        <Button htmlType='button' onClick={() => { form.resetFields(); }}>
                            Xóa điều kiện
                        </Button>
                        <Button htmlType='submit' type='primary'>Tìm kiếm</Button>
                    </div>
                </Form>
            </div>
            <RoleList form={form} searchParam={searchParam} />
        </>
    )
}

export default Index