import { Breadcrumb, Button, Col, Form, Input, Row, Select } from "antd";
import { ReactComponent as Home } from '../../../assets/logos/home.svg';
import { useEffect, useState } from "react";
import ProvinceList from "./components/DistrictList";
import { useLazyListProvinceQuery } from "../province/redux/provinceApi";

const RegisterInfo = () => {
    const [form] = Form.useForm();
    const [searchParam, setSearchParam] = useState({});
    const [triggerProvince, responseProvince] = useLazyListProvinceQuery();

    useEffect(() => {
        triggerProvince({});
    }, [triggerProvince]);

    const onFinish = (values: any) => {
        setSearchParam(values);
    }


    return (
        <>
            <Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>
                <Breadcrumb.Item href="/home">
                    <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ
                </Breadcrumb.Item>
                <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>
                <Breadcrumb.Item href="">
                    <span>Danh mục Quận huyện</span>
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className='title-label' style={{ marginBottom: 20 }}>Danh mục Quận huyện</div>

            <div className='content-box' style={{ marginBottom: 30 }}>
                <Form
                    name="searchBox"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    labelWrap
                    labelAlign="left"
                    colon={false}
                >
                    <label className='search-title'>Lọc chung</label>
                    <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                        <Col span={8} xs={24} md={12} lg={8}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Mã quận huyện"
                                name="districtCode"
                            >
                                <Input allowClear placeholder="Nhập mã quận huyện" style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8} xs={24} md={12} lg={8}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Tên quận huyện"
                                name="districtName"
                            >
                                <Input allowClear placeholder="Nhập tên quận huyện" style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={24} md={12} lg={8} xl={8}>
                            <Form.Item label="Tỉnh/Thành phố" name="provinceId">
                                <Select
                                    placeholder="Chọn Tỉnh/Thành phố"
                                    showSearch
                                    allowClear
                                    listHeight={130}
                                    optionFilterProp="label"
                                    filterSort={(optionA, optionB) =>
                                        optionA?.label?.toLowerCase().localeCompare(optionB?.label?.toLowerCase()) 
                                    }
                                    options={responseProvince?.data?.filter((item)=> item.isDelete===0)?.map(item => ({
                                        label: item.provinceName,
                                        value: item.id,
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Trạng thái"
                                name="status"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn trạng thái"
                                    showArrow
                                >
                                    <Select.Option value={0}>Hoạt động</Select.Option>
                                    <Select.Option value={1}>Không hoạt động</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='search-box-button'>
                        <Button htmlType='button' onClick={() => { form.resetFields(); }}>
                            Xóa điều kiện
                        </Button>
                        <Button htmlType='submit' type='primary'>Tìm kiếm</Button>
                    </div>
                </Form>
            </div>
            <ProvinceList form={form} searchParam={searchParam} />
        </>
    )
}

export default RegisterInfo;