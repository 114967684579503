import React, { useEffect, useMemo, useState } from "react"
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { ReactComponent as ZoomOut } from '../../../assets/logos/zoom-out-line.svg';
import { ReactComponent as ZoomIn } from '../../../assets/logos/zoom-in-line.svg';
import { ReactComponent as Rotate } from '../../../assets/logos/rotate.svg';
import { useLazyGetFilePdfByNameQuery } from "../../data_init/redux/dataInitApi";
import { PDFDocumentProxy } from 'react-pdf/node_modules/pdfjs-dist/types/src/display/api';
import { Button, Modal } from "antd";

type Props = {
    visible: boolean,
    toggleModal: () => void,
    filePdf: any,
    indexCompare?: number
}

const ViewFilePdf = ({ visible, toggleModal, filePdf, indexCompare }: Props) => {
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [numPages, setNumPages] = useState(0);
    const [triggerFilePdf, filePdfResponse] = useLazyGetFilePdfByNameQuery();
    const file = useMemo(() => {
        return {
            url: process.env.REACT_APP_API_URL + `/api/v1/file/download-pdf/${filePdfResponse?.data?.[0]?.id}`,
            httpHeaders: {
                Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        }
    }, [filePdfResponse?.data?.[0]?.id]);
    useEffect(() => {
        getFilePdf();
    }, [triggerFilePdf])

    const getFilePdf = () => {
        let currentFilePdf = (filePdf?.filePDFName || "").split(".");
        if (currentFilePdf.length) {
            let indexFile = "00000".substr(0, (currentFilePdf[3] || "").length - ((parseInt(currentFilePdf[3] || '', 10) + (indexCompare || 0) + "").length))
                + (parseInt(currentFilePdf[3] || '', 10) + (indexCompare || 0));
            triggerFilePdf({
                districtCode: filePdf?.pathFile?.split("/")?.[0] || undefined,
                communeCode: filePdf?.pathFile?.split("/")?.[1] || undefined,
                fileName: [currentFilePdf[0], currentFilePdf[1], currentFilePdf[2], indexFile].join('.')
            })
        }
    }
    const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
        setNumPages(numPages);
    }
    return (
        <Modal
            className="custom-modal"
            visible={visible}
            title={<div>
                <div className="header-block">
                    <label className='search-title'>
                        {indexCompare && indexCompare > 0 ? "File pdf kế sau: " : "File pdf liền trước: "}
                        {filePdfResponse?.data?.[0]?.fileName || ''}
                    </label>
                    <ZoomOut
                        onClick={() => setScale(scale === 1 ? 1 : scale - 1)}
                        style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                    <ZoomIn
                        onClick={() => setScale(scale + 1)}
                        style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                    <Rotate
                        onClick={() => setRotate(rotate - 90)}
                        style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                </div>
            </div>}
            onCancel={() => toggleModal()}
            width={850}
            style={{ top: "10px" }}
            maskClosable={false}
            footer={[
                <React.Fragment key="footer">
                    <Button htmlType='button' onClick={toggleModal}>
                        Đóng
                    </Button>
                </React.Fragment>
            ]}>
            <div
                style={{
                    width: "100%",
                }}
                id="block-view-pdf"
            >

                <div style={{ overflowY: "auto", height: "calc(100vh - 160px)" }} >
                    <Document
                        file={file}
                        onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page height={800} width={800} rotate={rotate} scale={scale} key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                    </Document>
                </div>
            </div>
        </Modal>
    )
}

export default ViewFilePdf