export const PERMISSION_KEYS = {
    QUAN_TRI_HE_THONG: {
        NGUOI_DUNG: {
            DANH_SACH: "ROLE_SYSTEM_USER_VIEW",
            THEM_MOI: "ROLE_SYSTEM_USER_ADD",
            SUA: "ROLE_SYSTEM_USER_EDIT",
            XOA: "ROLE_SYSTEM_USER_DELETE",
        },
        NHOM_QUYEN: {
            DANH_SACH: "ROLE_SYSTEM_GROUP_VIEW",
            THEM_MOI: "ROLE_SYSTEM_GROUP_ADD",
            SUA: "ROLE_SYSTEM_GROUP_EDIT",
            XOA: "ROLE_SYSTEM_GROUP_DELETE",
        },
    },
    CHUC_NANG: {
        DANH_SACH: "ROLE_SYSTEM_AUTHORITY_VIEW",
        THEM_MOI: "ROLE_SYSTEM_AUTHORITY_ADD",
        SUA: "ROLE_SYSTEM_AUTHORITY_EDIT",
        XOA: "ROLE_SYSTEM_AUTHORITY_DELETE",
    },
    THAM_SO_HE_THONG: {
        DANH_SACH: "ROLE_SYSTEM_PARAM_VIEW",
        THEM_MOI: "ROLE_SYSTEM_PARAM_ADD",
        SUA: "ROLE_SYSTEM_PARAM_EDIT",
        XOA: "ROLE_SYSTEM_PARAM_DELETE",
    },
    DANH_MUC: {
        TINH_THANH: {
            DANH_SACH: "ROLE_SYSTEM_PROVINCE_VIEW",
            THEM_MOI: "ROLE_SYSTEM_PROVINCE_ADD",
            SUA: "ROLE_SYSTEM_PROVINCE_EDIT",
            XOA: "ROLE_SYSTEM_PROVINCE_DELETE",
        },
        QUAN_HUYEN: {
            DANH_SACH: "ROLE_SYSTEM_DISTRICT_VIEW",
            THEM_MOI: "ROLE_SYSTEM_DISTRICT_ADD",
            SUA: "ROLE_SYSTEM_DISTRICT_EDIT",
            XOA: "ROLE_SYSTEM_DISTRICT_DELETE",
        },
        PHUONG_XA: {
            DANH_SACH: "ROLE_SYSTEM_COMMUNE_VIEW",
            THEM_MOI: "ROLE_SYSTEM_COMMUNE_ADD",
            SUA: "ROLE_SYSTEM_COMMUNE_EDIT",
            XOA: "ROLE_SYSTEM_COMMUNE_DELETE",
        },
    },
    DU_LIEU_SO_HOA: {
        DANH_SACH: "ROLE_SYSTEM_DATAINFO_VIEW",
        THEM_MOI: "ROLE_SYSTEM_DATAINFO_ADD",
        SUA: "ROLE_SYSTEM_DATAINFO_EDIT",
        XOA: "ROLE_SYSTEM_DATAINFO_DELETE",
    },
    SAP_XEP_DU_LIEU_SO_HOA: {
        DANH_SACH: "ROLE_SYSTEM_DATAKEYMAPSORT_VIEW",
        THEM_MOI: "ROLE_SYSTEM_DATAKEYMAPSORT_ADD",
        SUA: "ROLE_SYSTEM_DATAKEYMAPSORT_EDIT",
        XOA: "ROLE_SYSTEM_DATAKEYMAPSORT_DELETE",
    },
}


interface DataType {
    key: React.Key;
    id: string;
    title: string;
    view: string;
    add: string;
    edit: string;
    delete: string;
    children?: DataType[];
}

export const data: DataType[] = [
        {
            key: '2',
            id: '',
            title: 'Chiến dịch',
            view: '',
            add: '',
            edit: '',
            delete: '',
        },
        {
            key: '63db69a3bcdb90514c3aa30d',
            id: '63db69a3bcdb90514c3aa30d',
            title: "Cấu hình trường thông tin",
            add: '63db69a3bcdb90514c3aa30e',
            edit: '63db69a3bcdb90514c3aa30f',
            delete: '63db69a3bcdb90514c3aa310',
            view: '63db69a3bcdb90514c3aa311',
        },
        {
            title: "Kiểm tra dữ liệu",
            key: "ktdl1",
            id: "63db69a3bcdb90514c3aa321",
            add: '63db69a3bcdb90514c3aa322',
            edit: '63db69a3bcdb90514c3aa323',
            delete: '63db69a3bcdb90514c3aa324',
            view: '63db69a3bcdb90514c3aa325',
        },
        {
            title: "Kho dữ liệu kiểm tra",
            key: "ktdl2",
            id: "63db69a3bcdb90514c3aa321",
            add: '63db69a3bcdb90514c3aa322',
            edit: '63db69a3bcdb90514c3aa323',
            delete: '63db69a3bcdb90514c3aa324',
            view: '63db69a3bcdb90514c3aa325',
        },
        {
            title: "Quản lý người dùng",
            key: "63db69a3bcdb90514c3aa2fe",
            id: "63db69a3bcdb90514c3aa2fe",
            add: '63db69a3bcdb90514c3aa2ff',
            edit: '63db69a3bcdb90514c3aa300',
            delete: '63db69a3bcdb90514c3aa301',
            view: '63db69a3bcdb90514c3aa302',
        },
        {
            title: "Phân quyền",
            key: "63db69a3bcdb90514c3aa303",
            id: "63db69a3bcdb90514c3aa303",
            add: '63db69a3bcdb90514c3aa304',
            edit: '63db69a3bcdb90514c3aa305',
            delete: '63db69a3bcdb90514c3aa306',
            view: '63db69a3bcdb90514c3aa307',
        },
        {
            title: 'Quản lý Danh mục',
            key: 'danhmuc',
            id: 'danhmuc',
            add: 'addDM',
            edit: 'editDM',
            delete: 'deleteDM',
            view: 'viewDM',
            children: [
                {
                    title: 'Danh mục Tỉnh/Thành phố',
                    key: "63db69a3bcdb90514c3aa312",
                    id: "63db69a3bcdb90514c3aa312",
                    add: '63db69a3bcdb90514c3aa313',
                    edit: '63db69a3bcdb90514c3aa314',
                    delete: '63db69a3bcdb90514c3aa315',
                    view: '63db69a3bcdb90514c3aa316',
                },
                {
                    title: "Danh mục Quận/Huyện",
                    key: "63db69a3bcdb90514c3aa317",
                    id: "63db69a3bcdb90514c3aa317",
                    add: '63db69a3bcdb90514c3aa318',
                    edit: '63db69a3bcdb90514c3aa319',
                    delete: '63db69a3bcdb90514c3aa31a',
                    view: '63db69a3bcdb90514c3aa31b',
                },
                {
                    title: "Danh mục Phường/Xã",
                    key: "63db69a3bcdb90514c3aa31c",
                    id: "63db69a3bcdb90514c3aa31c",
                    add: '63db69a3bcdb90514c3aa31d',
                    edit: '63db69a3bcdb90514c3aa31e',
                    delete: '63db69a3bcdb90514c3aa31f',
                    view: '63db69a3bcdb90514c3aa320',
                },

            ]
        },
        

    ];
