import { Button, Col, Collapse, Form, Input, notification, Row, Select, Skeleton, Spin } from "antd";
import {
    useLazyGetFieldConfigQuery, useLazyGetRandomDataQuery,
    useUpdateDataByCampaignMutation, useUpdateDataInfoInitByCampaignMutation
} from "../redux/checkDataApi";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { FieldConfig } from "../redux/apiTypes";
import { ReactComponent as Top } from '../../../assets/logos/layout-top-line.svg';
import { ReactComponent as Left } from '../../../assets/logos/layout-left-line.svg';
import { ReactComponent as Right } from '../../../assets/logos/layout-right-line.svg';
import { ReactComponent as ZoomOut } from '../../../assets/logos/zoom-out-line.svg';
import { ReactComponent as ZoomIn } from '../../../assets/logos/zoom-in-line.svg';
import { ReactComponent as Rotate } from '../../../assets/logos/rotate.svg';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { PDFDocumentProxy } from 'react-pdf/node_modules/pdfjs-dist/types/src/display/api';
import ConfirmModal from "../../common/components/ConfirmModal";
import useResizeObserver from '@react-hook/resize-observer';
import { DEFAULT_FIELD_CONFIG, TYPE_FILE_PDF } from "../../../assets/ts/constant";
import { useLazyGetCommuneAllQuery, useLazyGetCommuneQuery, useLazyGetDistrictAllQuery, useLazyGetDistrictQuery, useLazyGetProvinceQuery } from "../../../redux/api/commonApi";
import { Commune, District } from "../../../redux/api/apiTypes";
import { useLazyGetSortIdQuery } from "../../data/redux/dataCheckApi";
import { helpers } from "../../../assets/js/helper";
import { CheckCircleOutlined, ClockCircleOutlined, ExclamationCircleOutlined, FullscreenOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { useLazyGetDataInfoInitByFilePdfQuery, useLazyGetFilePdfByIdQuery } from "../../data_init/redux/dataInitApi";
import ViewFilePdf from "./ViewFilePdf";
import SplitPane from "react-split-pane";
import DragAndDropTable from "../../data_init/components/DragAndDropTable";
import { ColumnsType } from "antd/es/table";
const { Panel } = Collapse;

const useWidth = (target: any) => {
    const [width, setWidth] = useState(300);

    useLayoutEffect(() => {
        setWidth(target.current.getBoundingClientRect().width)
    }, [target]);

    useResizeObserver(target, (entry: any) => setWidth(entry.contentRect.width));
    return width;
};

const CreateDataInfo = () => {
    const [form] = Form.useForm();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [numPages, setNumPages] = useState(0);
    const [orderNumber, setOrderNumber] = useState(1);
    const [pdfFileName, setPdfFileName] = useState('');
    const [displayFlex, setDisplayFlex] = useState(true);
    const [width, setWidth] = useState<any>();
    const [initWidth, setInitWidth] = useState<any>();
    const [height, setHeight] = useState<any>();
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [keyMaps, setKeyMaps] = useState<FieldConfig[]>([]);
    const [districts, setDistricts] = useState<District[]>([]);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [confirmNextextVisible, setConfirmNextextVisible] = useState(false);
    const [indexCompare, setIndexCompare] = useState<number>();
    const [viewPdfVisible, setViewPdfVisible] = useState(false);

    const [triggerData, dataResponse] = useLazyGetRandomDataQuery<any>();
    const [triggerProvince, responseProvince] = useLazyGetProvinceQuery();
    const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
    const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
    const [triggerCurrentSort, currentSortResponse] = useLazyGetSortIdQuery();
    const [triggerGetFieldConfig, fieldConfigResponse] = useLazyGetFieldConfigQuery();
    const [updateData] = useUpdateDataByCampaignMutation();
    const [updateDataInit, { isLoading }] = useUpdateDataInfoInitByCampaignMutation();
    const [listActive, setListActive] = useState<String[]>([]);
    const [triggerFilePdfId, detailResponse] = useLazyGetFilePdfByIdQuery();
    const [communeAllTrigger, responseCommuneAll] = useLazyGetCommuneAllQuery();
    const [districtAllTrigger, responseDistrictAll] = useLazyGetDistrictAllQuery();
    const [tableData, setTableData] = useState<any[]>([]);
    const [columns, setColumns] = useState<ColumnsType<any>>([]);
    const [triggerGetDataInfoInitByFilePdf, dataInfoInitByFilePdfResponse] = useLazyGetDataInfoInitByFilePdfQuery();
    const wrapperDiv = useRef<HTMLInputElement>(null);
    const file = useMemo(() => {
        let uri = '';
        if (dataResponse?.data?.filePDFId) {
            uri = `/api/v1/file/download-pdf/${dataResponse?.data?.filePDFId}`;
        } else if (dataResponse?.data?.dataFile?.id) {
            uri = `/api/v1/file/download-file/${dataResponse?.data?.dataFile?.id}`;
        }
        if (uri) {
            return {
                url: process.env.REACT_APP_API_URL + uri,
                httpHeaders: {
                    Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        }
    }, [dataResponse?.data?.filePDFId]);
    // }, [dataResponse?.data?.id, dataResponse?.data?.filePDFId]);

    useEffect(() => {
        triggerData({});
        districtAllTrigger({}).unwrap()
            .then(dists => setDistricts(dists));
        communeAllTrigger({}).unwrap()
            .then(coms => setCommunes(coms));
    }, [triggerData, communeAllTrigger, districtAllTrigger]);

    useEffect(() => {
        const slider = document.getElementById("block-view-pdf");
        let isDown = false;
        let startX: any;
        let startY: any;
        let scrollLeft: any;
        let scrollTop: any;
        if (slider) {
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                startY = e.pageY - slider.offsetTop;
                scrollLeft = slider.scrollLeft;
                scrollTop = slider.scrollTop;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageY - slider.offsetTop;
                const walk = (x - startY) * 3; //scroll-fast
                slider.scrollTop = scrollTop - walk;
            });
        }
    }, []);

    useEffect(() => {
        if (displayFlex) {
            setWidth((wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 2 || undefined);
            setInitWidth((wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 2 || undefined);
        }
    }, [wrapperDiv?.current?.getBoundingClientRect()?.width, displayFlex]);

    useEffect(() => {
        if (fieldConfigResponse?.data?.fieldKeyMap) {
            setColumns([
                ...(fieldConfigResponse?.data?.fieldKeyMap || "")
                    .filter((x: any) =>
                        // x.isDefault && 
                        x.isEdit &&
                        x.dataKeyAscii !== "tinhThanh" &&
                        x.dataKeyAscii !== "quanHuyen" &&
                        x.dataKeyAscii !== "phuongXa"
                    )
                    .map((item: any, index: number) => {
                        return {
                            title: item.dataKey,
                            dataIndex: item.dataKeyAscii,
                            key: item.dataKeyAscii,
                            align: "center",
                            width: item.dataKeyAscii == "so" ||
                                item.dataKeyAscii === "quyenSo" ||
                                item.dataKeyAscii === "trangSo" ||
                                item.type === "date" ? 125 : 250,
                            fixed: item.dataKeyAscii == "so" ||
                                item.dataKeyAscii === "quyenSo" ? 'left' : '',
                            // render: (record: any) => {
                            //     console.log(record);
                            //     return ;
                            // },
                        }
                    })
            ]);
        }
    }, [fieldConfigResponse]);

    useEffect(() => {
        if (dataInfoInitByFilePdfResponse?.data?.length && columns?.length) {
            setTableData(dataInfoInitByFilePdfResponse?.data?.map(item => {
                return {
                    key: item.id,
                    // ...item.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataKeyAscii === "quyenSo" ? <Link to={`/data/lack/info_init_detail/${item.id}`}>{v.dataValue || ""}</Link> : v.dataValue }), {})
                    ...item.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataValue }), {})
                }
            }));
        } else {
            setTableData([]);
        }
    }, [dataInfoInitByFilePdfResponse, columns]);

    useEffect(() => {
        // If have filePdfId is DataInfoInit\
        if (dataResponse?.isFetching || !districts?.length || !communes?.length) return;
        if (dataResponse?.data?.filePDFId) {
            triggerProvince({});
            let typeFile = dataResponse?.data?.filePDFName?.split(".")?.[0];
            if (typeFile) {
                triggerFilePdfId(dataResponse?.data?.filePDFId).unwrap().then(res => {
                    triggerGetDataInfoInitByFilePdf(dataResponse?.data?.filePDFId);
                    triggerGetFieldConfig({ types: typeFile, years: convertYearToTypePdf(typeFile) }).unwrap().then(res => {
                        let dataKeys = dataResponse?.data?.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
                        let dataFieldValue = dataResponse?.data?.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataValue }), {});

                        // Cập nhật thông tin đầu vào cho data info init
                        form.setFieldsValue(dataFieldValue);
                        if (dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh]) {
                            districtTrigger(dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh]).unwrap()
                                .then(dists => setDistricts(dists));
                        }
                        if (dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen]) {
                            communeTrigger(dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen]).unwrap()
                                .then(coms => setCommunes(coms));
                        }

                        if (dataFieldValue?.[DEFAULT_FIELD_CONFIG[typeFile]?.tenfilepdf]) {
                            setPdfFileName(dataFieldValue[DEFAULT_FIELD_CONFIG?.[typeFile]?.tenfilepdf]);
                        }

                        // Cập nhật thông tin key maps
                        setKeyMaps((res.fieldKeyMap || dataResponse?.data?.dataKeyMap).map((item: any, index: number) => {
                            return {
                                ...item,
                                // dataKey: dataKeys[item.dataKeyAscii]?.dataKey,
                                // dataKeyAscii: dataKeys[item.dataKeyAscii]?.dataKeyAscii,
                                dataValue: dataKeys[item.dataKeyAscii]?.dataValue,
                                isEdited: false,
                            }
                        }));
                    });
                })
            } else {
                setKeyMaps([]);
            }
            // else not filePdfId is DataInfo
        } else if (dataResponse?.data) {
            let dataKeys = dataResponse?.data?.keyMaps?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
            triggerCurrentSort(dataResponse?.data?.dataFile?.id).unwrap()
                .then((res: any) => {
                    setKeyMaps((res?.keyMaps || dataResponse?.data?.keyMaps).map((item: any, index: number) => {
                        return {
                            ...item,
                            dataKey: dataKeys[item.dataKeyAscii]?.dataKey,
                            dataKeyAscii: dataKeys[item.dataKeyAscii]?.dataKeyAscii,
                            dataValue: dataKeys[item.dataKeyAscii]?.dataValue,
                            isEdited: false,
                        }
                    }));
                }).catch((err: any) => {
                    setKeyMaps(dataResponse?.data?.keyMaps.map((item: any, index: number) => {
                        return {
                            ...item,
                            dataKey: dataKeys[item.dataKeyAscii]?.dataKey,
                            dataKeyAscii: dataKeys[item.dataKeyAscii]?.dataKeyAscii,
                            dataValue: dataKeys[item.dataKeyAscii]?.dataValue,
                            isEdited: false,
                        }
                    }));
                });
        } else {
            setKeyMaps([]);
            setPdfFileName('')
        }
    }, [triggerGetFieldConfig, triggerProvince, dataResponse?.data, districts, communes]);

    const convertYearToTypePdf = (typeFile: string) => {
        // KS.1994.01.--.001.PDF
        let yearStr = dataResponse?.data?.fileName?.split(".")?.[1];
        let yearNum = yearStr ? parseInt(yearStr) : 0;
        // TODO: LƯU Ý CHECK LẠI RULE NÀY KHI TRIỂN KHAI CHO QUẢNG NGÃI (ĐẶC BIỆT GIAI ĐOẠN <= 1977)
        // Chuyển từ năm sang file loại file: A3, A4
        if (typeFile === "KH" && yearNum === 2007 && detailResponse?.data?.communeCode === "xatinhantay") {
            return TYPE_FILE_PDF.A3;
        }
        if (yearNum > 2006) {
            if (typeFile === "KH" &&
                detailResponse?.data?.communeCode === "sotuphap" &&
                detailResponse?.data?.districtCode === "thanhphoquangngai") {
                if (yearNum < 2011) {
                    return TYPE_FILE_PDF.A3;
                }
            }
            return TYPE_FILE_PDF.A4;
        }
        if (yearNum > 1977) {
            return TYPE_FILE_PDF.A3;
        }
        switch (typeFile) {
            case "KS":
                return TYPE_FILE_PDF.A4
            default:
                return TYPE_FILE_PDF.A4;
        }
    }

    const onFinish = (values: any) => {
        form.validateFields().then(res => {
            toggleConfirmModal();
        })
    }

    const onSubmitCheckData = () => {
        let keyMapsObj = keyMaps.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataValue }), {});
        // If have filePdfId is DataInfoInit
        if (dataResponse?.data?.filePDFId &&
            fieldConfigResponse?.data?.type &&
            keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh] &&
            keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen] &&
            keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa]) {
            updateDataInit({
                dataKeyMap: keyMaps,
                id: dataResponse?.data?.id,
                isCheck: true,
                communeId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa],
                districtId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen],
                provinceId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh],
                campaignId: dataResponse?.data?.dataCampaignId
            }).unwrap()
                .then(res => {
                    notification.success({ message: "Cập nhật kết quả thành công" });
                    triggerData({});
                    toggleConfirmModal();
                    setListActive([]);
                }).catch(err => {
                    notification.error({ message: err.data?.message || "Có lỗi xảy ra!" })
                })
        } else {
            updateData({
                keyMaps,
                id: dataResponse?.data?.id,
                isCheck: true,
            }).unwrap()
                .then(res => {
                    notification.success({ message: "Cập nhật kết quả thành công" });
                    triggerData({});
                    toggleConfirmModal();
                }).catch(err => {
                    notification.error({ message: err.data?.message || "Có lỗi xảy ra!" })
                })
        }
    }

    const changeLayout = (newDisplayFlex: boolean, order: number) => {
        setDisplayFlex(newDisplayFlex);
        setOrderNumber(order);
        if (!newDisplayFlex) {
            setWidth(initWidth * 2);
        }
    }
    const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
        setNumPages(numPages);
    }

    // Format lại dữ liệu sau khi nhập liệu
    const updateDataInitBlur = (value: any, index: number, item: FieldConfig) => {
        if (item.type === "date") {
            value = helpers.customDate(value);
            changeDataInit(value, index, item);
        } else if (item.type === "time" && value) {
            value = helpers.customTime(value, item.format);
            changeDataInit(value, index, item);
        } else if (item.format?.toLowerCase().includes("capitalcase")) {
            value = helpers.toTitleCase(value);
            changeDataInit(value, index, item);
        } else {
            changeDataInit((value || "").trim(), index, item);
        }
        form.validateFields([item.dataKeyAscii]);
        onBlurItem(item);
    }

    const onBlurItem = (item: any) => {
        if (listActive.indexOf(item.dataKeyAscii) === -1) {
            setListActive([...listActive, item.dataKeyAscii]);
        }
    }

    // Change dataValue Of Data Info Init
    const changeDataInit = (value: any, index: number, item: FieldConfig) => {
        let dataKeys = dataResponse?.data?.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
        // Nếu không cho sửa thì không được sửa
        if (!item.isEdit) return;
        if (item?.dataKeyAscii) {
            form.setFieldsValue({ [item.dataKeyAscii]: value });
        }
        switch (item.dataKeyAscii) {
            case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].tinhThanh:
                form.setFieldsValue({ quanHuyen: undefined, phuongXa: undefined })
                setDistricts([]);
                setCommunes([]);
                districtTrigger(value).unwrap()
                    .then(res => setDistricts(res));
                break;
            case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].quanHuyen:
                form.setFieldsValue({ phuongXa: undefined })
                setCommunes([]);
                communeTrigger(value).unwrap()
                    .then(res => setCommunes(res));
                break;
        }
        // KS.2007.01.2007-07-11.082.pdf
        let changeFilePdf = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].so ||
            item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].quyenSo ||
            item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].ngayDangKy;
        let tenFilePdf = "";
        if (changeFilePdf) {
            let type = dataResponse?.data?.filePDFName?.split(".")?.[0];
            let so = form?.getFieldsValue()?.so;
            let quyenSo = form?.getFieldsValue()?.quyenSo?.split("/");
            let ngayDangKy = form?.getFieldsValue()?.ngayDangKy?.split(".") || '';
            ngayDangKy = ngayDangKy.length === 3 ? ngayDangKy : [];
            tenFilePdf = `${type}.${quyenSo?.[1] || '--'}.${quyenSo?.[0] || '--'}.${ngayDangKy?.[2] || ""}-${ngayDangKy?.[1] || ""}-${ngayDangKy?.[0] || ""}.${so || ""}.pdf`;
            form.setFieldsValue({
                [DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].tenfilepdf]: tenFilePdf,
            })
            setPdfFileName(tenFilePdf);
        }
        setKeyMaps(keyMaps.map((x, i) => {
            if (x.dataKeyAscii === item.dataKeyAscii) return {
                ...x,
                dataValue: value || "",
                isEdited: (value !== dataKeys[item?.dataKeyAscii || ""]?.dataValue) && !!(dataKeys[item?.dataKeyAscii || ""]?.dataValue || value),
            }
            if (x.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf && changeFilePdf) return {
                ...x,
                dataValue: tenFilePdf,
                isEdited: value !== dataKeys[item?.dataKeyAscii || ""]?.dataValue,
            }
            if ((x.dataKeyAscii || "").toLowerCase().includes((item.dataKeyAscii || "").toLowerCase()) &&
                (x.dataKeyAscii || "").toLowerCase().includes("bangchu")) {
                let txt = helpers.dateToWord(value || '');
                form.setFieldsValue({ [x.dataKeyAscii]: txt });
                return {
                    ...x,
                    dataValue: txt
                }
            }
            return x;
        }));
    }

    // Change dataValue of Data info
    const changeDataInfo = (e: any, key: string) => {
        let dataKeys = dataResponse?.data?.keyMaps?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
        let currentKeyMaps = [...keyMaps];
        currentKeyMaps = currentKeyMaps.map(item => {
            if (item.dataKeyAscii === key) {
                return {
                    ...item,
                    dataValue: e.target.value || "",
                    isEdited: e.target.value !== dataKeys[key].dataValue,
                }
            }
            return item;
        })
        setKeyMaps(currentKeyMaps);
    }

    const toggleConfirmModal = () => {
        setConfirmVisible(!confirmVisible);
    }

    // Convert object field to element
    const convertTypeDataToElement = (item: FieldConfig, index: number) => {
        if (item.data && item.data.length) {
            return <Form.Item
                name={item.dataKeyAscii}
                style={{ marginBottom: 10 }}
                label={<div style={{ display: "flex" }}>
                    {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                        <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                    <div style={{ fontWeight: 700, wordBreak: "break-word", color: item.isEdited ? "#ff4d4f" : "" }}>{item.dataKey || ""}</div>
                </div>}
                rules={createRules(item)}
            >
                <Select
                    disabled={!item.isEdit}
                    allowClear
                    placeholder={item.placeholder || "--Lựa chọn--"}
                    optionFilterProp='label'
                    options={(item.data || []).map((x: any) => ({
                        label: `${x.id}${x.name ? (" - " + x.name) : ""}`,
                        value: x.id
                    }))}
                    showSearch
                    showArrow
                    onChange={(value) => changeDataInit(value, index, item)}
                    onBlur={() => onBlurItem(item)}
                    value={item.dataValue}
                >
                </Select>
            </Form.Item>
        }

        if (item.isDefault && fieldConfigResponse?.data?.type && (!item.data || !item.data.length)) {
            switch (item.dataKeyAscii) {
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].tinhThanh:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                                <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                            <div style={{ fontWeight: 700, wordBreak: "break-word", color: item.isEdited ? "#ff4d4f" : "" }}>{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            disabled={!item.isEdit}
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(responseProvince?.data || []).map((item) => ({
                                label: item.provinceName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeDataInit(value, index, item)}
                            onBlur={() => onBlurItem(item)}
                            value={item.dataValue}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].quanHuyen:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                                <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                            <div style={{ fontWeight: 700, wordBreak: "break-word", color: item.isEdited ? "#ff4d4f" : "" }}>{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            disabled={!item.isEdit}
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(districts || []).map((item) => ({
                                label: item.districtName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeDataInit(value, index, item)}
                            onBlur={() => onBlurItem(item)}
                            value={item.dataValue}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].phuongXa:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                                <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                            <div style={{ fontWeight: 700, wordBreak: "break-word", color: item.isEdited ? "#ff4d4f" : "" }}>{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            disabled={!item.isEdit}
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(communes || []).map((item) => ({
                                label: item.communeName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeDataInit(value, index, item)}
                            onBlur={() => onBlurItem(item)}
                            value={item.dataValue}
                        >
                        </Select>
                    </Form.Item>
            }
        }
        return <Form.Item
            name={item.dataKeyAscii}
            style={{ marginBottom: 10 }}
            label={<div style={{ display: "flex" }}>
                {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                    <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                <div style={{ fontWeight: 700, wordBreak: "break-word", color: item.isEdited ? "#ff4d4f" : "" }}>{item.dataKey || ""}</div>
            </div>}
            rules={createRules(item)}
        ><Input.TextArea
                placeholder={item.placeholder || ''}
                disabled={!item.isEdit}
                onFocus={(e) => e.currentTarget.select()}
                onChange={(e) => changeDataInit(e.target.value, index, item)}
                // value={item.dataValue}
                onBlur={(e) => updateDataInitBlur(e.target.value, index, item)}
            /></Form.Item>;
    }



    const createRules = (item: any) => {
        let rules: Object[] = [];
        if (item.isDefault) {
            rules = [...rules, { required: true, message: "Trường dữ liệu bắt buộc" }];
        }
        if (item.format && item.format !== "capitalcase") {
            rules = [...rules, { pattern: new RegExp(item.format), message: "Dữ liệu không đúng định dạng" }];
        }
        return rules;
    }

    const checkChangeData = () => {
        return keyMaps.find(x => x.isEdited);
    }

    // Chuyển dữ liệu khác
    const nextDataInfo = () => {
        if (confirmNextextVisible) {
            triggerData({});
            if (confirmNextextVisible) {
                setConfirmNextextVisible(!confirmNextextVisible);
            }
            setListActive([]);
        } else {
            setConfirmNextextVisible(!confirmNextextVisible);
        }
    }

    const checkActiveSave = () => {
        let check = true;
        keyMaps.filter(x => x.isEdit && x.isDisplay).map(item => {
            if (item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh &&
                item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen &&
                item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa &&
                listActive.indexOf(item.dataKeyAscii) === -1) check = false;
        })
        return check;
    }

    const toggleViewPdfModal = () => {
        setViewPdfVisible(!viewPdfVisible);
    }

    const changeSize = (size?: number) => {
        if (displayFlex) setWidth(size);
        setHeight(size);
    }
    return (
        // <Spin tip="Đang tải..." spinning={fieldConfigResponse.isFetching}>
        <>
            <div className="content-box" >
                <div className="header-block" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <UserOutlined
                            style={{
                                cursor: 'pointer',
                                width: 20,
                                height: 20,
                                marginBottom: 0
                            }} />
                        <span style={{ margin: "0px 3px" }}>
                            {dataResponse?.data?.createBy}
                        </span>
                        - <ClockCircleOutlined style={{ cursor: 'pointer', width: 20, height: 20, marginBottom: 0 }} />
                        <span style={{ margin: "0px 3px" }}>{moment(dataResponse?.data?.createdDate).format("HH:mm DD/MM/YYYY")}</span>
                    </div>
                    <div>
                        <Top onClick={() => changeLayout(false, 1)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                        {/* <Right onClick={() => changeLayout(true, 3)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} /> */}
                        {/* <Bottom onClick={() => changeLayout(false, 2)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} /> */}
                        <Left onClick={() => changeLayout(true, 1)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                    </div>
                </div>
                <div style={{ margin: "10px 0px" }}>
                    <Collapse defaultActiveKey={['1']} style={{ width: "100%" }}>
                        <Panel
                            header={<div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <div>{`Dữ liệu đã nhập: ${tableData?.length} THHT`}</div>
                                {/* <div onClick={e => e.stopPropagation()}><FullscreenOutlined onClick={toggleListDataInfoInitModal} /></div> */}
                            </div>}
                            key="list_data_info"
                        >
                            {/* <Table<any>
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => { toggleEditDataModal(); setCurrentDataInitActive(record) }, // click row
                                    };
                                }}
                                tableLayout="auto"
                                pagination={false}
                                scroll={{ x: "max-content", y: 500 }}
                            /> */}
                            <DragAndDropTable
                                toggleEditDataModal={() => {}}
                                setCurrentDataInitActive={() => {}}
                                setTableData={setTableData}
                                tableData={tableData}
                                columns={columns}
                            />
                        </Panel>
                    </Collapse>
                </div>
                <div className="body-block-create-info-init" ref={wrapperDiv}>
                    <SplitPane
                        split={displayFlex ? "vertical" : "horizontal"} size={displayFlex ? width : height}
                        minSize={"50%"}
                        onChange={(size) => changeSize(size)}
                        maxSize={"100%"}
                    >
                        <div
                            style={displayFlex ? {
                                order: orderNumber,
                                padding: 10,
                                width: "100%",
                                // resize: "horizontal",
                                overflow: "auto"
                            } : {
                                // borderBottom: orderNumber === 1 ? "dashed 2px #E5E5E5" : "",
                                order: orderNumber,
                                padding: 10,
                                width: "100%",
                                overflow: "auto"
                            }}
                        >
                            <div className="header-block">
                                <div>
                                    <label className='search-title'>File PDF: {dataResponse?.data?.filePDFName || ''}</label>
                                    <ZoomOut
                                        onClick={() => setScale(scale === 1 ? 1 : scale - 1)}
                                        style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                                    <ZoomIn
                                        onClick={() => setScale(scale + 1)}
                                        style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                                    <Rotate
                                        onClick={() => setRotate(rotate - 90)}
                                        style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                                </div>
                                <div>
                                    <label
                                        style={{ wordBreak: "break-word" }}
                                        className='search-title'
                                    >({dataResponse?.data?.pathFile || ''})</label>
                                </div>
                                <div>
                                    <label onClick={() => { toggleViewPdfModal(); setIndexCompare(-1) }} style={{ color: "#1890ff", cursor: "pointer" }} className='search-title'>Xem file trước</label> | <label onClick={() => { toggleViewPdfModal(); setIndexCompare(1) }} style={{ color: "#1890ff", cursor: "pointer" }} className='search-title'>Xem file sau</label>
                                </div>
                            </div>
                            <div
                                style={{
                                    overflowY: "auto",
                                    height: displayFlex ? "calc(100vh - 150px)" : "calc(100% - 80px)",
                                }}
                                id="block-view-pdf"
                            >
                                <Document
                                    file={file}
                                    onLoadSuccess={onDocumentLoadSuccess}>
                                    {Array.from(new Array(numPages), (el, index) => (
                                        <Page
                                            width={width}
                                            height={height}
                                            rotate={rotate}
                                            scale={scale}
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                        />
                                    ))}
                                </Document>
                            </div>
                        </div>
                        <Skeleton loading={dataResponse.isFetching}>
                            <div
                                style={displayFlex ? {
                                    order: orderNumber === 1 ? 3 : 1,
                                    padding: 10,
                                    width: "100%",
                                    boxSizing: "border-box",
                                    position: "absolute"
                                    // resize: "horizontal",
                                    // overflow: "auto"
                                } : {
                                    // borderBottom: orderNumber !== 1 ? "dashed 2px #E5E5E5" : "",
                                    order: orderNumber === 1 ? 3 : 1,
                                    padding: 10,
                                    width: "100%",
                                    boxSizing: "border-box",
                                    // position: "absolute"
                                }}
                            >
                                <Form
                                    name="searchBox"
                                    autoComplete="off"
                                    form={form}
                                    colon={false}
                                    style={{ marginTop: 0 }}
                                    layout="vertical"
                                    labelWrap
                                    labelAlign="left"
                                >
                                    <label className='search-title'>Dữ liệu THHT: {pdfFileName}</label>
                                    <div style={{ overflowY: "auto", overflowX: "hidden", height: "calc(100vh - 215px)", paddingRight: 10 }}>
                                        <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                                            {keyMaps?.length && keyMaps.filter(x => x.isDisplay).map((item: any, index) => {
                                                return (<Col span={8} key={item.dataKeyAscii}>
                                                    {dataResponse?.data?.filePDFId ? convertTypeDataToElement(item, index) : <Form.Item
                                                        style={{ marginBottom: 10 }}
                                                        label={<div style={{ fontWeight: 700, wordBreak: "break-word" }}>
                                                            {item.dataKey || ""}
                                                            {item.isEdited ?
                                                                <ExclamationCircleOutlined title="Đã chỉnh sửa" style={{ marginLeft: 5, color: "#faad14" }} /> :
                                                                <></>
                                                                // <CheckCircleOutlined title="Chưa chỉnh sửa" color="success" style={{ marginLeft: 5, color: "#52c41a" }} />
                                                            }
                                                        </div>}
                                                    >
                                                        <Input.TextArea onFocus={(e) => e.currentTarget.select()} onChange={(e) => changeDataInfo(e, item.dataKeyAscii)} value={item.dataValue} placeholder="Nhập dữ liệu" />
                                                    </Form.Item>}
                                                </Col>)
                                            })}
                                        </Row>
                                    </div>
                                </Form>
                                {keyMaps?.length ? <div className='search-box-button' style={{ marginTop: 15 }}>
                                    <Button onClick={nextDataInfo}>Kiểm tra dữ liệu khác</Button>
                                    <Button disabled={!checkActiveSave() || isLoading} type='primary' onClick={onFinish}>{checkChangeData() ? "Cập nhật dữ liệu" : "Xác nhận"}</Button>
                                </div> : <></>}
                            </div>
                        </Skeleton>
                    </SplitPane>
                </div>
            </div >
            {confirmVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText="Xác nhận"
                    classBtnOk="ant-button-dangerous"
                    contents={[checkChangeData() ? "Bạn muốn cập nhật dữ liệu không?" : "Bạn muốn xác nhận dữ liệu không?"]}
                    handleCancel={toggleConfirmModal}
                    handleOk={onSubmitCheckData}
                    type={1}
                    visible={confirmVisible}
                    widthConfig={500}
                    okBtnDanger={true}
                    isLoading={isLoading}
                />
                : <></>}
            {confirmNextextVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText="Đồng ý"
                    classBtnOk="ant-button-dangerous"
                    contents={[checkChangeData() ?
                        "Bạn đồng ý huỷ kết quả chỉnh sửa dữ liệu này để kiểm tra dữ liệu khác?" :
                        "Bạn chắc chắn muốn chuyển file pdf?"]}
                    handleCancel={() => setConfirmNextextVisible(!confirmNextextVisible)}
                    handleOk={nextDataInfo}
                    type={1}
                    visible={confirmNextextVisible}
                    widthConfig={424}
                    okBtnDanger={true} />
                : <></>}
            {viewPdfVisible ?
                <ViewFilePdf
                    visible={viewPdfVisible}
                    toggleModal={toggleViewPdfModal}
                    filePdf={dataResponse?.data}
                    indexCompare={indexCompare}
                />
                : <></>}
        </>
    )
}

export default CreateDataInfo;