export const STATUS = {
    DEACTIVE: 0,
    ACTIVE: 1,
}

export const STATUS_LABEL: Record<number, string> = {
    0: "Không hoạt động",
    1: "Hoạt động",
}

export const TYPE_DATA: Record<string, string> = {
    "CC": "Thay đổi Cải Chính hộ tịch",
    "CMC": "Đăng ký nhận Cha mẹ con ",
    "CN": "Nhận Nuôi con nuôi ",
    "GH": "Giám hộ",
    "HN": "Xác nhận tình trạng Hôn Nhân",
    "KH": "Đăng ký kết hôn",
    "KS": "Khai sinh",
    "KT": "Khai tử",
    "LH": "Ly Hôn",
    "CDGH": "Chấm dứt giám hộ"
}

export const TYPE_ACTION: Record<string, string> = {
    // "1": "Nhập liệu",
    "2": "Tự chỉnh sửa",
    "3": "Kiểm tra dữ liệu",
    "5": "Chỉnh sửa bổ sung",
}

export const DEFAULT_FIELD_CONFIG: Record<string, any> = {
    "tinhThanh": "qn",
    "noiDangKy": "noiDangKy",
    "noiCap": "noiCap",
    "HN": {
        "tinhThanh": "tinhThanh",
        "quanHuyen": "quanHuyen",
        "phuongXa": "phuongXa",
        "so": "so",
        "quyenSo": "quyenSo",
        "ngayDangKy": "ngayDangKy",
        "tenfilepdf": "tenfilepdf",
    },
    "CC": {
        "tinhThanh": "tinhThanh",
        "quanHuyen": "quanHuyen",
        "phuongXa": "phuongXa",
        "so": "so",
        "quyenSo": "quyenSo",
        "ngayDangKy": "ngayDangKy",
        "tenfilepdf": "tenfilepdf",
    },
    "KS": {
        "tinhThanh": "tinhThanh",
        "quanHuyen": "quanHuyen",
        "phuongXa": "phuongXa",
        "so": "so",
        "quyenSo": "quyenSo",
        "ngayDangKy": "ngayDangKy",
        "loaiDangKy": "loaiDangKy",
        "nksNgaySinh": "nksNgaySinh",
        "tenfilepdf": "tenfilepdf",
    },
    "CMC": {
        "tinhThanh": "tinhThanh",
        "quanHuyen": "quanHuyen",
        "phuongXa": "phuongXa",
        "so": "so",
        "quyenSo": "quyenSo",
        "ngayDangKy": "ngayDangKy",
        "tenfilepdf": "tenfilepdf",
    },
    "CN": {
        "tinhThanh": "tinhThanh",
        "quanHuyen": "quanHuyen",
        "phuongXa": "phuongXa",
        "so": "so",
        "quyenSo": "quyenSo",
        "ngayDangKy": "ngayDangKy",
        "tenfilepdf": "tenfilepdf",
    },
    "GH": {
        "tinhThanh": "tinhThanh",
        "quanHuyen": "quanHuyen",
        "phuongXa": "phuongXa",
        "so": "so",
        "quyenSo": "quyenSo",
        "ngayDangKy": "ngayDangKy",
        "tenfilepdf": "tenfilepdf",
    },
    "KH": {
        "tinhThanh": "tinhThanh",
        "quanHuyen": "quanHuyen",
        "phuongXa": "phuongXa",
        "so": "so",
        "quyenSo": "quyenSo",
        "ngayDangKy": "ngayDangKy",
        "tenfilepdf": "tenfilepdf",
    },
    "KT": {
        "tinhThanh": "tinhThanh",
        "quanHuyen": "quanHuyen",
        "phuongXa": "phuongXa",
        "so": "so",
        "quyenSo": "quyenSo",
        "ngayDangKy": "ngayDangKy",
        "loaiDangKy": "loaiDangKy",
        "nktNgayChet": "nktNgayChet",
        "tenfilepdf": "tenfilepdf",
    },
    "LH": {
        "tinhThanh": "tinhThanh",
        "quanHuyen": "quanHuyen",
        "phuongXa": "phuongXa",
        "so": "so",
        "quyenSo": "quyenSo",
        "ngayDangKy": "ngayDangKy",
        "tenfilepdf": "tenfilepdf",
    },
    "CDGH": {
        "tinhThanh": "tinhThanh",
        "quanHuyen": "quanHuyen",
        "phuongXa": "phuongXa",
        "so": "so",
        "quyenSo": "quyenSo",
        "ngayDangKy": "ngayDangKy",
        "tenfilepdf": "tenfilepdf",
    }
}

export const TYPE_FIELD = [
    {
        id: "string",
        name: "Ký tự"
    },
    {
        id: "number",
        name: "Số"
    },
    // {
    //     id: "boolean",
    //     name: "True/False"
    // }
];


// Type file pdf
export const TYPE_FILE_PDF: Record<string, string> = {
    "A4": "A4", // > 2005, < 1976: KS
    "A3": "A3", // Từ 1976 - 2005: 1976-1998: A3 theo chiều dọc, 1999 - 2005: A3 theo chiều ngang
}

// Danh sách nơi cấp UBND của tỉnh quảng ngãi
export const QN_UBND: Record<string, string> = {
    "qn-thanhphoquangngai-sotuphap": "UBND Tỉnh Quảng Ngãi",
    "qn-thanhphoquangngai-phongtuphap": "UBND thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-phuongchanhlo": "UBND phường Chánh Lộ, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-phuonglehongphong": "UBND phường Lê Hồng Phong, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-phuongnghiachanh": "UBND phường Nghĩa Chánh, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-phuongnghialo": "UBND phường Nghĩa Lộ, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-phuongnguyennghiem": "UBND phường Nguyễn Nghiêm, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-phuongquangphu": "UBND phường Quảng Phú, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-phuongtranhungdao": "UBND phường Trần Hưng Đạo, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-phuongtranphu": "UBND phường Trần Phú, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-phuongtruongquangtrong": "UBND phường Trương Quang Trọng, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-xanghiaan": "UBND xã Nghĩa An, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-xanghiadong": "UBND xã Nghĩa Dõng, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-xanghiadung": "UBND xã Nghĩa Dũng, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-xanghiaha": "UBND xã Nghĩa Hà, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-xanghiaphu": "UBND xã Nghĩa Phú, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-xatinhandong": "UBND xã Tịnh Ấn Đông, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-xatinhantay": "UBND xã Tịnh Ấn Tây, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-xatinhan": "UBND xã Tịnh An, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-xatinhchau": "UBND xã Tịnh Châu, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-xatinhhoa": "UBND xã Tịnh Hòa, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-xatinhkhe": "UBND xã Tịnh Khê, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-xatinhky": "UBND xã Tịnh Kỳ, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-xatinhlong": "UBND xã Tịnh Long, thành phố Quảng Ngãi",
    "qn-thanhphoquangngai-xatinhthien": "UBND xã Tịnh Thiện, thành phố Quảng Ngãi"
}

// Danh sách UBND của Thành phố Đà Nẵng
export const DN_UBND: Record<string, string> = {

}

export const NUMBER_WORD: Record<number, string> = {
    0: "không",
    1: "một",
    2: "hai",
    3: "ba",
    4: "bốn",
    5: "năm",
    6: "sáu",
    7: "bảy",
    8: "tám",
    9: "chín",
    10: "mười",
    11: "mười một",
    12: "mười hai",
}

export const NUMBER_WORD_UNIT: Record<number, string> = {
    1: "mốt",
    2: "hai",
    3: "ba",
    4: "bốn",
    5: "lăm",
    6: "sáu",
    7: "bảy",
    8: "tám",
    9: "chín",
    10: "mười",
    11: "mười một",
    12: "mười hai",
}